import { z } from "zod";
import { AvatarSchema_CAI } from "./other.cai";

// Anonymous
export const AnonymousSchema_CAI = z.object({
  username: z.string().default("ANONYMOUS"),
});

// Guest
export const GuestSchema_CAI = z.object({
  username: z.string(),
  id: z.number(),
  account: z.any().optional(),
  is_staff: z.boolean().default(false),
  subscription: z.number().optional(),
  is_human: z.boolean().default(true),
  name: z.string(),
  email: z.string().optional(),
  hidden_characters: z.array(z.unknown()),
  blocked_users: z.array(z.unknown()),
});

export const AccountSchema_CAI = z.object({
  user: z.object({
    username: z.string(),
    id: z.number(),
    first_name: z.string(),
    account: z.object({
      name: z.string(),
      avatar_type: z.string(),
      onboarding_complete: z.boolean(),
      avatar_file_name: z.string(),
      mobile_onboarding_complete: z.null(),
    }),
    is_staff: z.boolean(),
    subscription: z.null(),
  }),
  is_human: z.boolean(),
  name: z.string(),
  email: z.string(),
  needs_to_acknowledge_policy: z.boolean(),
  suspended_until: z.null(),
  hidden_characters: z.array(z.unknown()),
  blocked_users: z.array(z.unknown()),
  bio: z.null(),
  interests: z.null(),
});

export type Account_CAI = z.infer<typeof AccountSchema_CAI>;

// Profile
export const ProfileSchema_CAI = AvatarSchema_CAI.extend({
  name: z.string().optional(),
  avatar_type: z.string().optional(),
  onboarding_complete: z.boolean(),
  avatar_file_name: z.string().nullable(),
  mobile_onboarding_complete: z.number().nullable(),
  bio: z.string().nullable(),
  username: z.string(),
  id: z.number(),
  first_name: z.string().nullable(),
  is_staff: z.boolean(),
  subscription: z.record(z.unknown()).optional(),
  is_human: z.boolean(),
  email: z.string(),
  needs_to_acknowledge_policy: z.boolean(),
  suspended_until: z.date().nullable(),
  hidden_characters: z.array(z.string()),
  blocked_users: z.array(z.string()),
});

// Persona
export const PersonaSchema_CAI = AvatarSchema_CAI.extend({
  external_id: z.string(),
  title: z.string(),
  name: z.string(),
  visibility: z.string(),
  copyable: z.boolean(),
  greeting: z.string(),
  description: z.string(),
  identifier: z.string(),
  avatar_file_name: z.string(),
  songs: z.array(z.unknown()),
  img_gen_enabled: z.boolean(),
  base_img_prompt: z.string(),
  img_prompt_regex: z.string(),
  strip_img_prompt_from_msg: z.boolean(),
  definition: z.string(),
  default_voice_id: z.string(),
  starter_prompts: z.any(),
  comments_enabled: z.boolean(),
  categories: z.array(z.unknown()),
  user__username: z.string(),
  participant__name: z.string(),
  participant__user__username: z.string(),
  num_interactions: z.number(),
  voice_id: z.string(),
});

// PersonaShort
export const PersonaShortSchema_CAI = AvatarSchema_CAI.extend({
  external_id: z.string(),
  title: z.string().optional(),
  greeting: z.string(),
  description: z.string().optional(),
  definition: z.string(),
  avatar_file_name: z.string().nullable(),
  visibility: z.string(),
  copyable: z.boolean(),
  participant__name: z.string(),
  participant__num_interactions: z.number(),
  user__id: z.number(),
  user__username: z.string(),
  img_gen_enabled: z.boolean(),
  default_voice_id: z.string().optional(),
  is_persona: z.boolean(),
});

// Type inference
export type Anonymous_CAI = z.infer<typeof AnonymousSchema_CAI>;
export type Guest_CAI = z.infer<typeof GuestSchema_CAI>;
export type Profile_CAI = z.infer<typeof ProfileSchema_CAI>;
export type Persona_CAI = z.infer<typeof PersonaSchema_CAI>;
export type PersonaShort_CAI = z.infer<typeof PersonaShortSchema_CAI>;
