import { Box, Skeleton } from "@chakra-ui/react";

import { Content } from "../layouts/Page.layout";
import PageLayoutSuspense from "./PageLayout.suspense";

export default function RecentChatsPageSuspense() {
  return (
    <>
      <PageLayoutSuspense pageTitle="Characters">
        <Content>
          <Box height="16px" />
          <Skeleton height="110px" width="100%" borderRadius="xl" />
          <Box height="1rem" />
          <Skeleton height="110px" width="100%" borderRadius="xl" />
          <Box height="1rem" />
          <Skeleton height="110px" width="100%" borderRadius="xl" />
          <Box height="1rem" />
          <Skeleton height="110px" width="100%" borderRadius="xl" />
          <Box height="1rem" />
          <Skeleton height="110px" width="100%" borderRadius="xl" />
        </Content>
      </PageLayoutSuspense>
    </>
  );
}
