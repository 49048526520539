import React from "react";
import { Badge, Box, Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import CharacterAvatar from "../character/CharacterAvatar";

type RecentChatCardProps = {
  chat: {
    id: string;
    characterId: string;
    updatedAt: Date;
    turns: Array<{
      role: "user" | "assistant";
      content: string;
    }>;
  };
  character?: {
    id: string;
    name: string;
    imageUrl: string;
  };
};

const RecentChatCard: React.FC<RecentChatCardProps> = ({ chat, character }) => {
  const characterName = character?.name || "Unknown";
  const characterImageUrl = character?.imageUrl || "";

  const formatLastChatTime = (date: Date) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)}h ago`;
    return `${Math.floor(diffInSeconds / 86400)}d ago`;
  };

  const lastTurn = chat.turns[chat.turns.length - 1];
  const lastMessage = lastTurn?.content;
  const lastMessagePreview = lastMessage
    ? lastMessage.slice(0, 50) + (lastMessage.length > 50 ? "..." : "")
    : "No messages yet";

  const lastTurnUpdatedAt = lastTurn?.updatedAt;

  return (
    <Link to={`/chat/${chat.id}`}>
      <Box
        padding="4"
        backgroundColor="gray.900"
        borderRadius="16px"
        _hover={{ backgroundColor: "gray.800" }}
        transition="background-color 0.2s"
      >
        <Flex alignItems="flex-start">
          <CharacterAvatar
            character={{
              id: chat.characterId,
              name: characterName,
              imageUrl: characterImageUrl,
            }}
            size="md"
          />
          <Box ml="4" flex="1">
            <Flex justifyContent="space-between" alignItems="center" mb="2">
              <Text variant="16-semi" color="gray.100">
                {characterName}
              </Text>
              <Text variant="12-reg" color="gray.400">
                {formatLastChatTime(new Date(lastTurnUpdatedAt))}
              </Text>
            </Flex>
            <Text variant="14-reg" color="gray.300" mb="2">
              {lastMessagePreview}
            </Text>
            <Flex justifyContent="space-between" alignItems="center">
              <Badge
                colorScheme="blue"
                variant="subtle"
                borderRadius="full"
                px="2"
              >
                {chat.turns.length} messages
              </Badge>
              <Text variant="12-reg" color="gray.400">
                {lastMessage?.role === "assistant"
                  ? "AI replied"
                  : "You replied"}
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Link>
  );
};

export default RecentChatCard;
