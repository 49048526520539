import { useEffect, useMemo, useState } from "react";

import { api } from "~/lib/trpc";

export default function useChatCount(characterId: string) {
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const countChats = api.turn.count.useMutation({
    onSuccess: ({ count: newCount }) => {
      setCount(newCount ?? 0);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (characterId) {
      setIsLoading(true);
      countChats.mutate({ characterId });
    }
  }, [characterId]);

  const memoizedCount = useMemo(
    () => ({ count, isLoading }),
    [count, isLoading],
  );

  return {
    ...memoizedCount,
    refetch: () => countChats.mutate({ characterId }),
  };
}
