import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import { useAtom } from "jotai";

import type { Character } from "@charry/models";

import type { RouterOutput } from "~/lib/trpc";
import { api, trpc } from "~/lib/trpc";
import { CharacterState } from "../jotai/character.jotai";
import useIsAuthenticated from "./useIsAuthenticated.hook";

export default function useMyCharacter(userId?: string) {
  const { isAuthenticated } = useIsAuthenticated();
  const queryClient = useQueryClient();

  const characterListQuery = api.character.list.useQuery(
    { userId },
    {
      enabled: isAuthenticated && !!userId,
      refetchInterval: isAuthenticated ? 1000 * 60 : false,
      /**
       * todo: figure out how to better handle
       * suspense in react query
       * https://github.com/TanStack/query/discussions/6361
       * stupid hack
       */
      staleTime: isAuthenticated ? 0 : Infinity,
    },
  );

  const characterList = characterListQuery.data?.characters ?? [];

  const setCharacterList = React.useCallback(
    (fn: (current: Character[]) => Character[]) => {
      const queryKey = getQueryKey(api.character.list, { userId }, "query");
      const current =
        queryClient.getQueryData<RouterOutput["character"]["list"]>(queryKey);
      queryClient.setQueryData(queryKey, {
        ...current,
        characters: fn(current?.characters ?? []),
      });
    },
    [queryClient],
  );

  const pageSize = 50;
  const totalPages = Math.ceil(characterList.length / pageSize);
  const [currentPage, setCurrentPage] = React.useState(1);

  const paginatedCharacterList = characterList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  const refreshCharacterList = useMutation({
    mutationKey: ["refreshCharacterList", userId],
    mutationFn: async () => {
      return await trpc.character.list.query({ userId });
    },
    onSuccess: ({ characters }) => {
      if (!characters) return;
      setCharacterList(() => characters);
    },
  });

  const createCharacter = api.character.create.useMutation({
    onSuccess: ({ character, error }) => {
      if (!character) {
        throw new Error(error?.message ?? "Failed to create character");
      }

      setCharacterList((current) => [character, ...current]);
    },
  });

  const [createCharacterParams, setCreateCharacterParams] = useAtom(
    CharacterState.createCharacterParams,
  );

  const updateCharacter = api.character.update.useMutation({
    onSuccess: ({ character }) => {
      if (!character) return;

      setCharacterList((current) => {
        return current.map((c) => {
          if (c.id === character.id) {
            return character;
          }
          return c;
        });
      });
    },
  });

  const archiveCharacter = api.character.archive.useMutation({
    onSuccess: ({ character }) => {
      if (!character) return;

      setCharacterList((current) => {
        return current.map((c) => {
          if (c.id === character.id) {
            return character;
          }
          return c;
        });
      });
    },
  });

  return {
    characterList,
    refreshCharacterList,
    createCharacter,
    createCharacterParams,
    setCreateCharacterParams,
    updateCharacter,
    paginatedCharacterList,
    totalPages,
    currentPage,
    setCurrentPage,
    archiveCharacter,
  };
}
