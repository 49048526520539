import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  HStack,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { FiMessageCircle } from "react-icons/fi";

import PageLayoutSuspense from "./PageLayout.suspense";

export default function CharactersDetailContentSuspense() {
  return (
    <PageLayoutSuspense pageTitle="Character Details">
      <Box mt={8} ml={{ base: 4, md: 8 }}>
        <Container maxW="1368px" px={{ base: 4, md: 8 }}>
          <Grid templateColumns={{ base: "1fr", md: "300px 1fr" }} gap={6}>
            <GridItem>
              <Flex direction="column" align="center">
                <Skeleton
                  height="150px"
                  width="150px"
                  borderRadius="full"
                  mb={4}
                />
                <Skeleton height="24px" width="80%" mb={2} />
                <Skeleton height="20px" width="60%" mb={4} />
                <Skeleton height="20px" width="40%" mb={4} />
                <Button
                  variant="primary"
                  size="lg"
                  width="100%"
                  leftIcon={<FiMessageCircle />}
                  isLoading
                >
                  New Chat
                </Button>
              </Flex>
            </GridItem>
            <GridItem>
              <Box mb={6}>
                <Skeleton height="24px" width="120px" mb={2} />
                <Skeleton height="60px" width="100%" />
              </Box>
              <Box mb={6}>
                <Skeleton height="24px" width="150px" mb={2} />
                <Skeleton height="40px" width="100%" />
              </Box>
              <Box mb={6}>
                <Skeleton height="24px" width="140px" mb={2} />
                <Skeleton height="100px" width="100%" />
              </Box>
              <Box mb={6}>
                <Skeleton height="24px" width="120px" mb={2} />
                <HStack spacing={2} wrap="wrap">
                  {[...Array(5)].map((_, i) => (
                    <Skeleton
                      key={i}
                      height="24px"
                      width="80px"
                      borderRadius="full"
                    />
                  ))}
                </HStack>
              </Box>
            </GridItem>
          </Grid>
        </Container>
      </Box>
    </PageLayoutSuspense>
  );
}
