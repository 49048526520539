import React, { Suspense, useRef } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  InputGroup,
  InputLeftElement,
  Select,
  Text,
} from "@chakra-ui/react";
import { FiAperture, FiSearch } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";

import adventureBackground from "~/assets/char.png";
import CharacterCard from "~/ui/components/character/CharacterCard";
import CharacterCardList from "~/ui/components/character/CharacterCardList";
import LoginRegisterModal from "~/ui/components/dashboard/LoginRegisterModal";
import NoContentCard from "~/ui/components/NoContentCard";
import SearchInput from "~/ui/elements/SearchInput";
import useCharacterList from "~/ui/hooks/useCharacterList.hook";
import useIsAuthenticated from "~/ui/hooks/useIsAuthenticated.hook";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import useUser from "~/ui/hooks/useUser.hook";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";
import CharacterLayoutSuspense from "~/ui/suspense/CharacterLayout.suspense";
import CharacterListSuspense from "~/ui/suspense/CharacterList.suspense";

const categories = [
  "Helpers",
  "Anime Game Characters",
  "Games",
  "Anime",
  "Game Characters",
  "Books",
  "Comedy",
  "Image Generating",
  "Celebrities",
  "Vtuber",
  "Language Learning",
  "Discussion",
  "History",
  "Religion",
  "Animals",
  "Philosophy",
  "Politics",
  "Other",
];

const ExplorePageComponent = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { isAuthenticated } = useIsAuthenticated();
  const { user } = useUser();
  const [search, setSearch] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState<string | "">(
    "",
  );
  const [isLoginModalOpen, setIsLoginModalOpen] = React.useState(false);
  const { characterList, isLoading } = useCharacterList();

  const forYouRef = useRef<HTMLDivElement>(null);
  const featuredRef = useRef<HTMLDivElement>(null);
  const trendingRef = useRef<HTMLDivElement>(null);

  const filtered = React.useMemo(() => {
    return characterList.filter((character) => {
      const matchesName = character.name
        .toLowerCase()
        .includes(search.toLowerCase());
      const matchesCategory = selectedCategory
        ? character.category === selectedCategory
        : true;
      const isPublic = character.visibility === "Public";
      return matchesName && matchesCategory && isPublic;
    });
  }, [characterList, search, selectedCategory]);

  const handleCharacterClick = (characterId: string) => {
    // if (isAuthenticated) {
    navigate(`/character/${characterId}/`);
    // } else {
    //   setIsLoginModalOpen(true);
    // }
  };

  const scroll = (
    ref: React.RefObject<HTMLDivElement>,
    scrollOffset: number,
  ) => {
    if (ref.current) {
      ref.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <>
      <Header>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex alignItems="center">
            <Title>{isMobile ? "" : "Explore Characters"}</Title>
          </Flex>
          <Flex alignItems="center" gap={4}>
            <InputGroup width={{ base: "100%", md: "300px" }}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <SearchInput
                placeholder="Search characters"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
            <Select
              placeholder="All Categories"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              width={{ base: "100%", md: "200px" }}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              {categories.map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))}
            </Select>
          </Flex>
        </Flex>
      </Header>
      <Content shouldSuspendContent={false}>
        {isLoading ? (
          <CharacterLayoutSuspense />
        ) : (
          <>
            {filtered.length === 0 ? (
              <NoContentCard
                mt={4}
                Icon={FiAperture}
                Text={() => (
                  <Text>
                    No characters found for your search criteria.
                    {isAuthenticated && (
                      <>
                        <br />
                        <br />
                        You can create a new character below.
                      </>
                    )}
                  </Text>
                )}
                Button={() =>
                  isAuthenticated ? (
                    <Link to="/characters/create" style={{ width: "400px" }}>
                      <Button variant="primary" width="100%">
                        Create Character
                      </Button>
                    </Link>
                  ) : null
                }
              />
            ) : (
              <>
                <Box mb={4} mt={4}>
                  {isAuthenticated && user && (
                    <Flex flexDirection="column" alignItems="flex-start" mb={4}>
                      <Text fontSize="lg" color="gray.400">
                        Welcome back,
                      </Text>
                      <Heading as="h2" size="lg" mt={1}>
                        {user.username}
                      </Heading>
                    </Flex>
                  )}
                  <Grid
                    templateColumns={{ base: "2fr", md: "60% 40%" }}
                    gap={2}
                  >
                    <Box
                      p={6}
                      borderRadius="3xl"
                      backgroundImage={`url(${adventureBackground})`}
                      backgroundSize="cover"
                      backgroundPosition="center"
                    >
                      <Text
                        fontSize={{ base: "xl", md: "2xl" }}
                        fontWeight="bold"
                        mb={2}
                      >
                        What do you want to do?
                      </Text>
                      <Text
                        fontSize={{ base: "2xl", md: "3xl" }}
                        fontWeight="bold"
                        color="blue.400"
                      >
                        Go on an adventure!
                      </Text>
                    </Box>
                    <Flex
                      direction={{ base: "row", md: "column" }}
                      gap={{ base: 4, md: 2 }}
                      overflowX={{ base: "auto", md: "visible" }}
                      pb={{ base: 4, md: 0 }}
                    >
                      {filtered[0] && (
                        <Box
                          position="relative"
                          flexShrink={0}
                          width={{ base: "80%", md: "100%" }}
                        >
                          <CharacterCard
                            character={filtered[0]}
                            onClick={() => handleCharacterClick(filtered[0].id)}
                          />
                        </Box>
                      )}
                      {filtered[1] && (
                        <Box
                          position="relative"
                          flexShrink={0}
                          width={{ base: "80%", md: "100%" }}
                        >
                          <CharacterCard
                            character={filtered[1]}
                            onClick={() => handleCharacterClick(filtered[1].id)}
                          />
                        </Box>
                      )}
                    </Flex>
                  </Grid>
                </Box>

                <Box mb={4}>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mb={4}
                  >
                    <Heading as="h2" size="lg">
                      For you
                    </Heading>
                    <Flex>
                      <IconButton
                        icon={<ChevronLeftIcon />}
                        onClick={() => scroll(forYouRef, -300)}
                        aria-label="Scroll left"
                        mr={2}
                      />
                      <IconButton
                        icon={<ChevronRightIcon />}
                        onClick={() => scroll(forYouRef, 300)}
                        aria-label="Scroll right"
                      />
                    </Flex>
                  </Flex>
                  <Flex
                    ref={forYouRef}
                    overflowX="auto"
                    css={{
                      scrollbarWidth: "none",
                      "&::-webkit-scrollbar": { display: "none" },
                    }}
                  >
                    <CharacterCardList
                      characters={filtered.slice(0, 10)}
                      onCharacterClick={handleCharacterClick}
                      horizontal
                    />
                  </Flex>
                </Box>

                <Box mb={4}>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mb={4}
                  >
                    <Heading as="h2" size="lg">
                      Featured
                    </Heading>
                    <Flex>
                      <IconButton
                        icon={<ChevronLeftIcon />}
                        onClick={() => scroll(featuredRef, -300)}
                        aria-label="Scroll left"
                        mr={2}
                      />
                      <IconButton
                        icon={<ChevronRightIcon />}
                        onClick={() => scroll(featuredRef, 300)}
                        aria-label="Scroll right"
                      />
                    </Flex>
                  </Flex>
                  <Flex
                    ref={featuredRef}
                    overflowX="auto"
                    css={{
                      scrollbarWidth: "none",
                      "&::-webkit-scrollbar": { display: "none" },
                    }}
                  >
                    <CharacterCardList
                      characters={filtered.slice(10, 20)}
                      onCharacterClick={handleCharacterClick}
                      horizontal
                    />
                  </Flex>
                </Box>
                <Box mb={4}>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mb={4}
                  >
                    <Heading as="h2" size="lg">
                      Trending
                    </Heading>
                    <Flex>
                      <IconButton
                        icon={<ChevronLeftIcon />}
                        onClick={() => scroll(trendingRef, -300)}
                        aria-label="Scroll left"
                        mr={2}
                      />
                      <IconButton
                        icon={<ChevronRightIcon />}
                        onClick={() => scroll(trendingRef, 300)}
                        aria-label="Scroll right"
                      />
                    </Flex>
                  </Flex>
                  <Flex
                    ref={trendingRef}
                    overflowX="auto"
                    css={{
                      scrollbarWidth: "none",
                      "&::-webkit-scrollbar": { display: "none" },
                    }}
                  >
                    <CharacterCardList
                      characters={filtered.slice(20, 30)}
                      onCharacterClick={handleCharacterClick}
                      horizontal
                    />
                  </Flex>
                </Box>
              </>
            )}
          </>
        )}
      </Content>
      <LoginRegisterModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
      />
    </>
  );
};

export default function ExplorePage() {
  return (
    <Suspense fallback={<CharacterLayoutSuspense />}>
      <ExplorePageComponent />
    </Suspense>
  );
}
