import React, { useCallback } from "react";
import { Box, Button, Divider, Flex, Text, useToast } from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import useCharacter from "~/ui/hooks/useCharacter.hook";
import useModal from "~/ui/hooks/useModal.hook";
import { ConfirmModalTypes } from "../../modal/Confirm.modal";
import { ModalTypes } from "../../modal/Modal";
import CharacterDefinition from "./CharacterDefinition";
import CharacterDescription from "./CharacterDescription";
import CharacterGreeting from "./CharacterGreeting";
import CharacterImage from "./CharacterImage";
import CharacterName from "./CharacterName";
import CharacterTitle from "./CharacterTitle";
import CharacterVisibility from "./CharacterVisibility";
import UpdateCharacterMenu from "./UpdateCharacterMenu";

type EditCharacterProps = {
  characterId: string;
};

const EditCharacter: React.FC<EditCharacterProps> = ({ characterId }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { push, pop } = useModal();
  const { updateCharacterParams, setUpdateCharacterParams, archiveCharacter } =
    useCharacter(characterId);
  const [errors] = React.useState<Record<string, object>>({});
  const [showMore, setShowMore] = React.useState(false);

  const archive = useCallback(async () => {
    try {
      await archiveCharacter.mutateAsync({
        id: characterId,
      });

      navigate(`/dashboard/characters`);
    } catch (e) {
      console.error(e);
      toast({
        title: "Error Archiving Character",
        description:
          "Something went wrong. Please try again later or contact support.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [archiveCharacter, characterId, navigate, toast]);

  /** Render */
  return (
    <Box display="flex" paddingTop="24px" justifyContent="center">
      <Box width="600px">
        <CharacterName
          params={updateCharacterParams}
          setParams={setUpdateCharacterParams}
          errors={errors}
        />
        <Flex height="24px" />
        <CharacterImage
          params={updateCharacterParams}
          setParams={setUpdateCharacterParams}
          errors={errors}
        />
        <Flex height="24px" />
        <CharacterTitle
          params={updateCharacterParams}
          setParams={setUpdateCharacterParams}
          errors={errors}
        />
        <Flex height="24px" />
        <CharacterDescription
          params={updateCharacterParams}
          setParams={setUpdateCharacterParams}
          errors={errors}
        />
        <Flex height="24px" />
        <CharacterGreeting
          params={updateCharacterParams}
          setParams={setUpdateCharacterParams}
          errors={errors}
        />
        {showMore ? (
          <>
            <Flex height="24px" />
            <CharacterDefinition
              params={updateCharacterParams}
              setParams={setUpdateCharacterParams}
              errors={errors}
            />
            <Flex height="24px" />
            <CharacterVisibility
              params={updateCharacterParams}
              setParams={setUpdateCharacterParams}
              errors={errors}
            />
          </>
        ) : (
          <>
            <Flex height="16px" />
            <Button
              onClick={() => setShowMore(true)}
              variant="secondary"
              size="xs"
              rightIcon={<FiChevronDown />}
            >
              Show more options
            </Button>
          </>
        )}
        <Flex height="24px" />
        <UpdateCharacterMenu characterId={characterId} />

        <Box margin="32px 0">
          <Divider height="1px" backgroundColor="gray.800" />
        </Box>
        <Text variant="18-reg" color="white">
          Archive Character
        </Text>
        <Box height="8px" />
        <Text variant="14-reg" color="gray.400">
          If you wish to archive this character, you can do so by clicking the
          button below. This action can only be reversed by contacting support.
        </Text>
        <Box height="16px" />
        <Button
          isLoading={archiveCharacter.isPending}
          variant="error"
          size="sm"
          width="fit-content"
          onClick={() => {
            push({
              type: ModalTypes.Confirm,
              props: {
                title: `Archive Character`,
                type: ConfirmModalTypes.Warning,
                message: `You are about to archive this character. Are you sure you want to continue?`,
                confirm: async () => {
                  await archive();
                  pop();
                },
                cancel: () => {
                  pop();
                },
              },
            });
          }}
        >
          Archive this character
        </Button>
        <Box height="300px" />
      </Box>
    </Box>
  );
};

export default EditCharacter;
