import { Flex } from "@chakra-ui/react";

import SystemTime from "../components/SystemTime";
import { Header, Title } from "../layouts/Page.layout";

type PageLayoutSuspenseProps = {
  pageTitle: string;
  children: React.ReactNode;
};

export default function PageLayoutSuspense({
  children,
  pageTitle,
}: PageLayoutSuspenseProps) {
  return (
    <>
      <Header>
        <Flex alignItems="center">
          <Title>{pageTitle}</Title>
        </Flex>
        {/* <SystemTime /> */}
      </Header>
      {children}
    </>
  );
}
