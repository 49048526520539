import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { StatusCodes } from "@charry/models";

import PosthogEvent from "~/constants/PosthogEvent.constants";
import { api } from "~/lib/trpc";
import ResendEmailVerificationComponent from "~/ui/components/ResendEmailVerificationComponent";
import { withSuspense } from "~/ui/hoc/withSuspense";
import useTracking from "~/ui/hooks/useTracking.hook";
import MaintenanceModeLayout from "~/ui/layouts/MaintenanceMode.layout";
import useLoginSuccessRedirect from "../ui/hooks/useLoginSuccessRedirect.hook";
import useUser from "../ui/hooks/useUser.hook";

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { track } = useTracking();
  const { loginSuccessRedirect } = useLoginSuccessRedirect();
  const { user, isAuthenticated, refreshUser, logout } = useUser();
  const [loading, setLoading] = React.useState(false);
  const [lastEmailRequestTimestamp, setLastEmailRequestTimestamp] =
    useState<Date | null>(user?.emailVerificationTokenRequestedAt ?? null);

  const sendEmailVerificationLinkMutation =
    api.user.sendEmailVerificationLink.useMutation();

  const handleRequestEmailVerification = useCallback(async () => {
    setLoading(true);
    try {
      const response = await sendEmailVerificationLinkMutation.mutateAsync();
      if (response.status !== StatusCodes.OK) {
        throw new Error(
          response.error?.message ?? "Failed to send email verification.",
        );
      }

      track({
        event: PosthogEvent.AuthRequestedEmailVerification,
      });

      setLastEmailRequestTimestamp(new Date());
      toast({
        title: "Success",
        description: "Email verification link sent. Please check your email.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Failed to request email verification.",
        description: "Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [toast, track, sendEmailVerificationLinkMutation]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
    if (user?.emailVerifiedAt != null) {
      void loginSuccessRedirect();
    }
  }, [user, isAuthenticated, navigate, loginSuccessRedirect]);

  useEffect(() => {
    if (lastEmailRequestTimestamp == null) {
      return;
    }

    const intervalId = setInterval(async () => {
      const refreshedUser = await refreshUser();
      if (refreshedUser?.emailVerifiedAt != null) {
        void loginSuccessRedirect();
      }
    }, 10_000);
    return () => clearInterval(intervalId);
  }, [refreshUser, lastEmailRequestTimestamp, loginSuccessRedirect]);

  const isLegacyUser = user?.emailVerificationRequired === false;

  return (
    <MaintenanceModeLayout>
      <Box
        backgroundColor="gray.1000"
        height="100vh"
        width="100vw"
        display="flex"
        justifyContent="center"
      >
        <Flex
          position="relative"
          top={{ base: "0", md: "96px" }}
          width={{ base: "100%", md: "420px" }}
          height="fit-content"
          flexDirection="column"
          alignItems="center"
          borderRadius="8px"
          boxSizing="border-box"
        >
          <Box height="24px" />
          {isLegacyUser ? (
            <>
              <Text variant="30-bold" color="white">
                Please verify your email.
              </Text>
              <Box height="12px" />
              <Text variant="16-reg" color="gray.400">
                Email verification is required for existing users.
              </Text>
            </>
          ) : (
            <>
              <Text variant="30-bold" color="white">
                Thanks for signing up!
              </Text>
              <Box height="12px" />
              <Text variant="16-reg" color="gray.400">
                Please verify your email address to continue.
              </Text>
            </>
          )}
          <Box height="32px" />
          <ResendEmailVerificationComponent
            disabled={loading}
            handleRequestEmailVerification={handleRequestEmailVerification}
            lastEmailRequestTimestamp={lastEmailRequestTimestamp}
          />
          <Button
            marginTop="24px"
            variant="outline"
            size="lg"
            width="100%"
            onClick={logout}
          >
            Logout
          </Button>
        </Flex>
      </Box>
    </MaintenanceModeLayout>
  );
};

export default withSuspense(VerifyEmailPage);
