import React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Link,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { FiAlertTriangle, FiArrowLeft, FiChevronDown } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { MAX_CHARACTERS } from "@charry/models";

import { DISCORD_INVITE_LINK, TWITTER_LINK } from "~/lib/const";
import Card from "~/ui/elements/Card";
import useUser from "~/ui/hooks/useUser.hook";
import useCharacterList from "../../../hooks/useCharacterList.hook";
import CharacterCategory from "./CharacterCategory";
import CharacterDefinition from "./CharacterDefinition";
import CharacterDescription from "./CharacterDescription";
import CharacterGreeting from "./CharacterGreeting";
import CharacterImage from "./CharacterImage";
import CharacterName from "./CharacterName";
import CharacterTags from "./CharacterTags";
import CharacterTitle from "./CharacterTitle";
import CharacterVisibility from "./CharacterVisibility";
import CreateCharacterMenu from "./CreateCharacterMenu";

const CreateCharacter: React.FC = () => {
  const { isSuper } = useUser();
  const { characterList, createCharacterParams, setCreateCharacterParams } =
    useCharacterList();
  const [errors] = React.useState<Record<string, object>>({});
  const navigate = useNavigate();
  const theme = useTheme();
  const [showMore, setShowMore] = React.useState(false);

  if (characterList.length >= MAX_CHARACTERS && !isSuper) {
    return (
      <Card width="100%" backgroundColor="gray.1000">
        <Flex alignItems="flex-start" justifyContent="space-between">
          <Box alignItems="center">
            <Flex alignItems="center">
              <FiAlertTriangle
                color={theme.colors.yellow[500]}
                fontSize="20px"
              />
              <Text variant="16-med" color="white" marginLeft="8px">
                Character Creation Unavailable
              </Text>
            </Flex>
            <Text variant="14-reg" color="gray.300" marginTop="4px">
              Each account is limited to {MAX_CHARACTERS} characters during the
              alpha phase of our rollout. Follow us on{" "}
              <Link href={TWITTER_LINK} target="_blank" textDecor="underline">
                Twitter
              </Link>{" "}
              or join our{" "}
              <Link
                href={DISCORD_INVITE_LINK}
                target="_blank"
                textDecor="underline"
              >
                Discord
              </Link>{" "}
              to stay updated on our progress.
              <br />
              <br />
              <b>
                You can archive a character in it's setting tab to make room for
                new characters.
              </b>
            </Text>
          </Box>
        </Flex>
        <div>
          <Button
            variant="primary"
            size="sm"
            marginTop="16px"
            onClick={() => navigate("/dashboard/characters")}
          >
            <FiArrowLeft />
            <Box width="8px" />
            Got it 🫡
          </Button>
        </div>
      </Card>
    );
  }

  /** Render */
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Text variant="24-reg" color="white">
            Create a character
          </Text>
          <Box height="4px" />
          <Text variant="14-reg" color="gray.400">
            Fill out the fields below to get started
          </Text>
        </Box>
      </Box>
      <Box margin="24px 0px 32px">
        <Divider height="1px" backgroundColor="gray.800" />
      </Box>
      <CharacterName
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
        errors={errors}
      />
      <Flex height="24px" />
      <CharacterImage
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
        errors={errors}
      />
      <Flex height="24px" />
      <CharacterTitle
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
        errors={errors}
      />
      <Flex height="24px" />
      <CharacterDescription
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
        errors={errors}
      />
      <Flex height="24px" />
      <CharacterGreeting
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
        errors={errors}
      />
      {showMore ? (
        <>
          <Flex height="24px" />
          <CharacterDefinition
            params={createCharacterParams}
            setParams={setCreateCharacterParams}
            errors={errors}
          />
          <Flex height="24px" />
          <CharacterVisibility
            params={createCharacterParams}
            setParams={setCreateCharacterParams}
            errors={errors}
          />
          <Flex height="24px" />
          <CharacterCategory
            params={createCharacterParams}
            setParams={setCreateCharacterParams}
            errors={errors}
          />
          <Flex height="24px" />
          <CharacterTags
            params={createCharacterParams}
            setParams={setCreateCharacterParams}
            errors={errors}
          />
        </>
      ) : (
        <>
          <Flex height="16px" />
          <Button
            onClick={() => setShowMore(true)}
            variant="secondary"
            size="xs"
            rightIcon={<FiChevronDown />}
          >
            Show more options
          </Button>
        </>
      )}

      <Box margin="24px 0px 32px">
        <Divider height="1px" backgroundColor="gray.800" />
      </Box>
      <CreateCharacterMenu />
      <Box height="300px" />
    </>
  );
};

export default CreateCharacter;
