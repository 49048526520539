import React from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";

import type {
  CharacterVisibility,
  CreateCharacterParams,
  UpdateCharacterParams,
} from "@charry/models";

import type useCharacter from "~/ui/hooks/useCharacter.hook";
import type useCharacterList from "~/ui/hooks/useCharacterList.hook";

type Params = CreateCharacterParams | UpdateCharacterParams;

type CharacterCategoryProps = {
  params: Params;
  setParams:
    | ReturnType<typeof useCharacterList>["setCreateCharacterParams"]
    | ReturnType<typeof useCharacter>["setUpdateCharacterParams"];
  errors: { category?: string };
};

const categories = [
  "Helpers",
  "Anime Game Characters",
  "Games",
  "Anime",
  "Game Characters",
  "Books",
  "Comedy",
  "Image Generating",
  "Celebrities",
  "Vtuber",
  "Language Learning",
  "Discussion",
  "History",
  "Religion",
  "Animals",
  "Philosophy",
  "Politics",
  "Other",
];

const CharacterCategory: React.FC<CharacterCategoryProps> = ({
  params,
  setParams,
  errors,
}) => {
  return (
    <Flex direction="column">
      <FormControl isInvalid={!!errors.category}>
        <FormLabel>Category</FormLabel>
        <Select
          placeholder="Select category"
          value={params.category}
          onChange={(e) => {
            const category = e.target.value;
            setParams((prev) => ({
              ...prev,
              category,
            }));
          }}
          style={{ paddingTop: "0px", paddingBottom: "0px" }}
        >
          {categories.map((cat) => (
            <option key={cat} value={cat}>
              {cat}
            </option>
          ))}
        </Select>
        {errors.category && (
          <FormErrorMessage>{errors.category}</FormErrorMessage>
        )}
      </FormControl>
    </Flex>
  );
};

export default CharacterCategory;
