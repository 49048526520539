import { z } from "zod";

export const AuthorSchemaV2_CAI = z.object({
  author_id: z.string(),
  name: z.string(),
  is_human: z.boolean().optional(),
});

export const EditorSchemaV2_CAI = z.object({
  author_id: z.string(),
  name: z.string().optional(),
});

export const TurnKeySchemaV2_CAI = z.object({
  chat_id: z.string(),
  turn_id: z.string(),
});

export const CandidateSchemaV2_CAI = z.object({
  candidate_id: z.string(),
  create_time: z.string(),
  raw_content: z.string(),
  editor: EditorSchemaV2_CAI.optional(),
  is_final: z.boolean(),
  base_candidate_id: z.string().optional(),
});

export const BotAnswerSchemaV2_CAI = z.object({
  turn_key: TurnKeySchemaV2_CAI,
  create_time: z.string(),
  last_update_time: z.string(),
  state: z.string(),
  author: AuthorSchemaV2_CAI,
  candidates: z.array(CandidateSchemaV2_CAI),
  primary_candidate_id: z.string(),
});

export const TurnDataSchemaV2_CAI = z.object({
  turn_key: TurnKeySchemaV2_CAI,
  create_time: z.string(),
  last_update_time: z.string(),
  state: z.string(),
  author: AuthorSchemaV2_CAI,
  candidates: z.array(CandidateSchemaV2_CAI),
  primary_candidate_id: z.string(),
});

export const ChatDataSchemaV2_CAI = z.object({
  chat_id: z.string(),
  create_time: z.string(),
  creator_id: z.string(),
  character_id: z.string(),
  state: z.string(),
  type: z.string(),
  visibility: z.string(),
  preview_turns: z.array(TurnDataSchemaV2_CAI).optional(),
});

export const MetaSchemaV2_CAI = z.object({
  next_token: z.string(),
});

export const HistorySchemaV2_CAI = z.object({
  turns: z.array(TurnDataSchemaV2_CAI),
  meta: MetaSchemaV2_CAI,
});

export type AuthorV2_CAI = z.infer<typeof AuthorSchemaV2_CAI>;
export type EditorV2_CAI = z.infer<typeof EditorSchemaV2_CAI>;
export type TurnKeyV2_CAI = z.infer<typeof TurnKeySchemaV2_CAI>;
export type CandidateV2_CAI = z.infer<typeof CandidateSchemaV2_CAI>;
export type TurnDataV2_CAI = z.infer<typeof TurnDataSchemaV2_CAI>;
export type ChatDataV2_CAI = z.infer<typeof ChatDataSchemaV2_CAI>;
export type MetaV2_CAI = z.infer<typeof MetaSchemaV2_CAI>;
export type HistoryV2_CAI = z.infer<typeof HistorySchemaV2_CAI>;
