import React, { Suspense } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { FiMessageCircle } from "react-icons/fi";
import { Link, Outlet, useParams } from "react-router-dom";

import SubNavigationButton from "~/ui/elements/SubNavigationButton";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";
import useCharacter from "../hooks/useCharacter.hook";
import useChat from "../hooks/useChat.hook";
import CharacterDetailsPageSuspense from "../suspense/CharacterDetailsPage.suspense";

const CharacterActionButtons = ({ characterId }: { characterId: string }) => {
  const { character } = useCharacter(characterId);
  const { createChat } = useChat();

  if (!character) return null;

  const buttons = [];

  buttons.push(
    <Button
      variant="primary"
      size="sm"
      leftIcon={<FiMessageCircle />}
      onClick={async () => {
        await createChat.mutateAsync({
          characterId,
        });
      }}
      isLoading={createChat.isLoading}
    >
      New Chat
    </Button>,
  );

  return <Flex gap={1}>{buttons}</Flex>;
};

const CharacterLayoutComponent: React.FC = () => {
  const isMobile = useIsMobile();
  const { characterId } = useParams();
  const { character } = useCharacter(characterId);

  if (!character) {
    return (
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  return (
    <>
      <Header>
        <Box display="flex" alignItems="center">
          <Link to="/dashboard/characters">
            <Text variant="16-med" color="gray.300">
              Characters
            </Text>
          </Link>
          <Box width="8px" />
          <ChevronRightIcon fontSize="18px" color="gray.300" />
          <Box width="8px" />
          <Text variant="16-med">{character.name}</Text>
        </Box>
        <CharacterActionButtons characterId={character.id} />
      </Header>
      <Content>
        {/* {!isCurrentVersion ? (
          <Box display="flex" alignItems="center" marginTop="32px">
            <Card
              width="100%"
              backgroundColor="gray.1000"
              padding="24px"
              borderColor="gray.800"
              borderWidth="1px"
            >
              <Flex alignItems="flex-start" justifyContent="space-between">
                <Box alignItems="center">
                  <Flex alignItems="center">
                    <FiAlertTriangle
                      color={theme.colors.yellow[500]}
                      fontSize="20px"
                    />
                    <Text variant="16-med" color="white" marginLeft="8px">
                      Upgrade Required
                    </Text>
                  </Flex>
                  <Text variant="14-reg" color="gray.300" marginTop="4px">
                    This character is running an old version of the Kuzco client.
                    Please download and install the newest version to continue
                    participating on the Kuzco network. While running an out of
                    date character may work, it is safest to upgrade as soon as
                    possible.
                  </Text>
                  <Box height="16px" />
                  <Link to={DOWNLOAD_LINK} target="_blank">
                    <Button variant="primary" size="sm">
                      <FiArrowUpRight fontSize="18px" />
                      <Box width="8px" />
                      View Downloads
                    </Button>
                  </Link>
                </Box>
              </Flex>
            </Card>
          </Box>
        ) : null} */}
        <Box display="flex" alignItems="center" marginTop="32px">
          {/* <Image
            alt="Bot Logo"
            src={"/images/context-mark.svg"}
            width="48"
            height="48"
            style={{
              maxWidth: "48px",
              height: "48px",
              borderRadius: "6px",
            }}
          />
          <Box width="12px" /> */}
          <Box>
            <Title>{character.name}</Title>
            <Text variant="14-reg" color="gray.400">
              {/* {0}&nbsp;documents */}
            </Text>
          </Box>
        </Box>
        <Box height="24px" />
        <Box
          display="flex"
          width="100%"
          borderBottom="1px solid"
          borderColor="gray.800"
          paddingBottom="8px"
        >
          {isMobile ? (
            <>
              <Box display="flex" width="min-content">
                <SubNavigationButton
                  label="Main"
                  link={`/character/${character.id}`}
                />
                <SubNavigationButton
                  label="Instances"
                  link={`/dashboard/characters/instances/${character.id}`}
                />
                <SubNavigationButton
                  label="Logs"
                  link={`/dashboard/characters/logs/${character.id}`}
                />
                <SubNavigationButton
                  label="Gens"
                  link={`/dashboard/characters/generations/${character.id}`}
                />
                <SubNavigationButton
                  label="Benchmarks"
                  link={`/dashboard/characters/benchmarks/${character.id}`}
                />
                <SubNavigationButton
                  label="Settings"
                  link={`/dashboard/characters/settings/${character.id}`}
                />
              </Box>
            </>
          ) : (
            <>
              <Box display="flex" width="min-content">
                <SubNavigationButton
                  label="Overview"
                  link={`/dashboard/characters/details/${character.id}`}
                />
                <SubNavigationButton
                  label="Instances"
                  link={`/dashboard/characters/instances/${character.id}`}
                />
                <SubNavigationButton
                  label="Logs"
                  link={`/dashboard/characters/logs/${character.id}`}
                />
                <SubNavigationButton
                  label="Generations"
                  link={`/dashboard/characters/generations/${character.id}`}
                />
                <SubNavigationButton
                  label="Benchmarks"
                  link={`/dashboard/characters/benchmarks/${character.id}`}
                />
                <SubNavigationButton
                  label="Settings"
                  link={`/dashboard/characters/settings/${character.id}`}
                />
              </Box>
            </>
          )}
        </Box>
      </Content>
      <Content>
        <Outlet />
        <Box height="200px" />
      </Content>
    </>
  );
};

export default function CharacterLayout() {
  return (
    <Suspense fallback={<CharacterDetailsPageSuspense />}>
      <CharacterLayoutComponent />
    </Suspense>
  );
}
