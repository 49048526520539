import React from "react";
import { useQuery } from "@tanstack/react-query";

import { AppConfigContext } from "../contexts/AppConfig.context";

export const useAppConfig = () => {
  const context = React.useContext(AppConfigContext);

  const runtimeTypeQuery = useQuery({
    queryKey: ["runtimeType"],
    queryFn: () => context.runtimeType(),
  });

  return {
    ...context,
    runtimeType: runtimeTypeQuery,
  };
};
