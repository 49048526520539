import React from "react";
import { Box, Flex, Image, Spinner, Text } from "@chakra-ui/react";

import type {
  CreateCharacterParams,
  UpdateCharacterParams,
} from "@charry/models";

import type useCharacter from "~/ui/hooks/useCharacter.hook";
import type useCharacterList from "~/ui/hooks/useCharacterList.hook";
import FileUpload from "~/ui/components/FileUpload";
import Label from "~/ui/elements/Label";

type CharacterImageProps = {
  params: CreateCharacterParams | UpdateCharacterParams;
  setParams:
    | ReturnType<typeof useCharacterList>["setCreateCharacterParams"]
    | ReturnType<typeof useCharacter>["setUpdateCharacterParams"];
  errors: { imageUrl?: string };
};

const CharacterImage: React.FC<CharacterImageProps> = ({
  params,
  setParams,
}) => {
  const [imageUrl, setImageUrl] = React.useState<string | null>(
    params.imageUrl ?? null,
  );

  /** Render */
  return (
    <Flex direction="column" flex="1">
      <Label>
        Image{" "}
        <Text as="span" color="gray.400" fontSize="inherit">
          (Recommended)
        </Text>
      </Label>
      {imageUrl ? (
        <Flex direction="column">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100px"
            width="100px"
            boxSizing="border-box"
            position="relative"
            cursor="pointer"
            borderRadius="4px"
            border="1px solid"
            borderColor="gray.600"
            backgroundColor="gray.1000"
            overflow="hidden"
          >
            {!params.imageUrl ? (
              <Spinner />
            ) : (
              <Image
                alt="Character Image"
                src={imageUrl}
                width="256"
                height="256"
                style={{
                  maxWidth: "100px",
                  height: "100px",
                }}
              />
            )}
          </Box>
          <Text
            marginTop="4px"
            variant="12-semi"
            color="white"
            onClick={() => {
              void setParams(
                // @ts-expect-error - need better types here.
                (params: CreateCharacterParams | UpdateCharacterParams) => {
                  return {
                    ...params,
                    imageUrl: null,
                  };
                },
              );
              setImageUrl(null);
            }}
            _hover={{
              cursor: "pointer",
            }}
          >
            Clear Image
          </Text>
        </Flex>
      ) : (
        <FileUpload
          accept={{
            "image/*": [".jpeg", ".png"],
          }}
          onStartUpload={([imageUrl]: string[]) => {
            if (imageUrl) {
              setImageUrl(imageUrl);
            }
          }}
          onCompleted={([imageUrl]: string[]) => {
            if (imageUrl) {
              setImageUrl(imageUrl);
            }
            void setParams(
              // @ts-expect-error - need better types here.
              (params: CreateCharacterParams | UpdateCharacterParams) => {
                return {
                  ...params,
                  imageUrl,
                };
              },
            );
          }}
        />
      )}
    </Flex>
  );
};

export default CharacterImage;
