import React from "react";
import { Flex } from "@chakra-ui/react";

import type {
  CreateCharacterParams,
  UpdateCharacterParams,
} from "@charry/models";

import type useCharacter from "~/ui/hooks/useCharacter.hook";
import type useCharacterList from "~/ui/hooks/useCharacterList.hook";
import Input from "../../../elements/Input";

type CharacterTitleProps = {
  params: CreateCharacterParams | UpdateCharacterParams;
  setParams:
    | ReturnType<typeof useCharacterList>["setCreateCharacterParams"]
    | ReturnType<typeof useCharacter>["setUpdateCharacterParams"];
  errors: { title?: string };
};

const CharacterTitle: React.FC<CharacterTitleProps> = ({
  params,
  setParams,
  errors,
}) => {
  return (
    <Flex direction="column">
      <Input
        type="text"
        label="Tagline"
        size="lg"
        value={params.title ?? ""}
        isInvalid={errors.title ? true : false}
        placeholder="Add a short tagline for your character"
        onChange={(e) => {
          const title = e.currentTarget.value;
          // @ts-expect-error - need better types here.
          void setParams((params) => {
            return {
              ...params,
              title: title,
            };
          });
        }}
      />
    </Flex>
  );
};

export default CharacterTitle;
