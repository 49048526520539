import { Box, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import CreateCharacter from "~/ui/components/character/create/CreateCharacter";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";

const CreateCharacterPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header>
        <Title>Create Character</Title>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => {
            navigate("/dashboard/characters");
          }}
        >
          Cancel
        </Button>
      </Header>
      <Content>
        <Box display="flex" paddingTop="24px" justifyContent="center">
          <Box width="600px">
            <CreateCharacter />
          </Box>
        </Box>
      </Content>
    </>
  );
};

export default CreateCharacterPage;
