import type { BoxProps } from "@chakra-ui/react";
import type { ReactNode } from "react";
import { Suspense } from "react";
import { Box, Spinner, Text } from "@chakra-ui/react";

import useIsMobile from "~/ui/hooks/useIsMobile.hook";

const NAVIGATION_MAX_WIDTH = "100%";
const CONTENT_MAX_WIDTH = "1368px";

export const Header = ({ children, width, ...rest }: BoxProps) => {
  const isMobile = useIsMobile();
  return (
    <Box
      width="100%"
      height="64px"
      borderBottom="1px solid"
      borderColor="gray.800"
      display="flex"
      alignItems="center"
      justifyContent="center"
      boxSizing="border-box"
      backgroundColor="gray.1100"
      padding={isMobile ? "0 15px" : "0 40px"}
      {...rest}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        maxWidth={NAVIGATION_MAX_WIDTH}
        width={width ?? "100%"}
      >
        {children}
      </Box>
    </Box>
  );
};

export const Subheader = ({ children }: { children: ReactNode }) => (
  <Box
    width="100%"
    height="56px"
    borderBottom="1px solid"
    borderColor="gray.800"
    display="flex"
    alignItems="center"
    justifyContent="center"
    boxSizing="border-box"
    backgroundColor="gray.1100"
  >
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      maxWidth={NAVIGATION_MAX_WIDTH}
      width="100%"
    >
      {children}
    </Box>
  </Box>
);

export const Title = ({ children }: { children: ReactNode }) => (
  <Text variant="18-semi" color="white">
    {children}
  </Text>
);

export const Subtitle = ({ children }: { children: ReactNode }) => (
  <Text variant="16-bold" color="white">
    {children}
  </Text>
);

type ContentProps = BoxProps & {
  children: ReactNode;
  shouldSuspendContent?: boolean;
  suspenseFallback?: ReactNode;
};

export const Content = ({
  children,
  shouldSuspendContent = true,
  suspenseFallback,
  ...rest
}: ContentProps) => {
  const isMobile = useIsMobile();
  const shouldUseSuspense = shouldSuspendContent || suspenseFallback != null;
  return (
    <Box width="100%" alignItems="center" display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={CONTENT_MAX_WIDTH}
        padding={isMobile ? "0 24px" : "0 40px"}
        width="100%"
        {...rest}
      >
        {shouldUseSuspense ? (
          <Suspense
            fallback={
              suspenseFallback ?? (
                <Box
                  position="absolute"
                  height="80vh"
                  maxWidth={CONTENT_MAX_WIDTH}
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  backgroundColor="gray.1100"
                >
                  <Spinner />
                </Box>
              )
            }
          >
            {children}
          </Suspense>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};
