const emails = [
  "sam@charry.ai",
  "abe@charry.ai",
  "carsen@charry.ai",
  "carsen@kuzco.xyz",
  "sean@charry.ai",
];

export const isSuperUser = (email: string | null | undefined): boolean => {
  return email ? emails.includes(email) : false;
};
