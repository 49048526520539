import React from "react";
import { Flex } from "@chakra-ui/react";

import type {
  CreateCharacterParams,
  UpdateCharacterParams,
} from "@charry/models";

import type useCharacter from "~/ui/hooks/useCharacter.hook";
import type useCharacterList from "~/ui/hooks/useCharacterList.hook";
import Textarea from "../../../elements/Textarea";

type CharacterGreetingProps = {
  params: CreateCharacterParams | UpdateCharacterParams;
  setParams:
    | ReturnType<typeof useCharacterList>["setCreateCharacterParams"]
    | ReturnType<typeof useCharacter>["setUpdateCharacterParams"];
  errors: { greeting?: string };
};

const CharacterGreeting: React.FC<CharacterGreetingProps> = ({
  params,
  setParams,
  errors,
}) => {
  return (
    <Flex direction="column">
      <Textarea
        label="Greeting"
        size="lg"
        value={params.greeting}
        isInvalid={errors.greeting ? true : false}
        placeholder="e.g. 'Hello, I'm George Washington. Ask me anything.'"
        onChange={(e) => {
          const greeting = e.currentTarget.value;
          // @ts-expect-error - need better types here.
          void setParams((params) => {
            return {
              ...params,
              greeting: greeting,
            };
          });
        }}
      />
    </Flex>
  );
};

export default CharacterGreeting;
