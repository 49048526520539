import React from "react";

import CharacterAvatar from "~/ui/components/character/CharacterAvatar";
import { NavButton } from "./NavButton";

type RecentChatNavButtonProps = {
  label: string;
  link: string;
  isCollapsed: boolean;
  characterName: string;
  characterImageUrl: string;
};

const RecentChatNavButton: React.FC<RecentChatNavButtonProps> = ({
  //   label,
  link,
  isCollapsed,
  characterName,
  characterImageUrl,
}) => {
  return (
    <NavButton
      label={characterName} // or label for recent chat message?
      link={link}
      isCollapsed={isCollapsed}
      leftElement={
        <CharacterAvatar
          size="xs"
          character={{
            id: "",
            name: characterName,
            imageUrl: characterImageUrl,
          }}
          mr={2}
        />
      }
    />
  );
};

export default RecentChatNavButton;
