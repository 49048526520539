import * as React from "react";
import { SettingsIcon } from "@chakra-ui/icons";
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import { FiLogOut } from "react-icons/fi";
import { Link } from "react-router-dom";

import Card from "~/ui/elements/Card";
import useUser from "~/ui/hooks/useUser.hook";

const AuthMenu: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, logout } = useUser();

  return (
    <Popover placement="right-end">
      <PopoverTrigger>
        <Box _hover={{ cursor: "pointer" }}>{children}</Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          border="0px"
          backgroundColor="transparent"
          _focusVisible={{ boxShadow: "0", outline: "0" }}
        >
          <Card
            padding="0px"
            maxWidth="240px"
            backgroundColor="gray.1000"
            maxHeight="600px"
          >
            <Box>
              <Text padding="12px 16px 0px" variant="14-reg" color="gray.400">
                {user?.email}
              </Text>
            </Box>
            <Box height="4px" />
            <Box>
              <Link to="/settings">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  padding="0 16px"
                  height="42px"
                  _hover={{
                    backgroundColor: "gray.900",
                    cursor: "pointer",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <SettingsIcon />
                    <Box width="12px" />
                    <Text variant="14-med" color="white">
                      Settings
                    </Text>
                  </Box>
                </Box>
              </Link>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="0 16px"
                height="42px"
                _hover={{
                  backgroundColor: "gray.900",
                  cursor: "pointer",
                }}
                onClick={() => logout()}
              >
                <Box display="flex" alignItems="center">
                  <FiLogOut />
                  <Box width="12px" />
                  <Text variant="14-med" color="white">
                    Logout
                  </Text>
                </Box>
              </Box>
            </Box>
          </Card>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default AuthMenu;
