import React from "react";
import { Flex } from "@chakra-ui/react";

import type {
  CreateCharacterParams,
  UpdateCharacterParams,
} from "@charry/models";

import type useCharacterList from "../../../hooks/useCharacterList.hook";
import type useCharacter from "~/ui/hooks/useCharacter.hook";
import Input from "../../../elements/Input";

type CharacterNameProps = {
  params: CreateCharacterParams | UpdateCharacterParams;
  setParams:
    | ReturnType<typeof useCharacterList>["setCreateCharacterParams"]
    | ReturnType<typeof useCharacter>["setUpdateCharacterParams"];
  errors: { name?: string };
};

const CharacterName: React.FC<CharacterNameProps> = ({
  params,
  setParams,
  errors,
}) => {
  return (
    <Flex direction="column">
      <Input
        autoFocus
        type="text"
        size="lg"
        label="Name"
        value={params.name}
        isInvalid={errors.name ? true : false}
        placeholder="e.g. George Washington"
        onChange={(e) => {
          const name = e.currentTarget.value;
          // @ts-expect-error - need better types here.
          void setParams((params) => {
            return {
              ...params,
              name: name,
            };
          });
        }}
      />
    </Flex>
  );
};

export default CharacterName;
