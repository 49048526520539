import React from "react";
import { Button, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import parseMutationError from "~/lib/parseMutationError";
import useCharacterList from "~/ui/hooks/useCharacterList.hook";

const CreateCharacterMenu: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const { createCharacter, createCharacterParams } = useCharacterList();
  const [_errors, setErrors] = React.useState<Record<string, unknown>>({});

  const create = async () => {
    // Client-side validation
    const newErrors: Record<string, string> = {};
    if (!createCharacterParams.name) newErrors.name = "Name is required";
    if (!createCharacterParams.description)
      newErrors.description = "Description is required";
    if (!createCharacterParams.greeting)
      newErrors.greeting = "Greeting is required";
    if (!createCharacterParams.title) newErrors.title = "Title is required";
    if (!createCharacterParams.category)
      newErrors.category = "Category is required";
    if (!createCharacterParams.tags) newErrors.tags = "Tags are required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast({
        title: "Missing Required Fields",
        description:
          "Please fill in all required fields before creating the character.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      setLoading(true);
      const { character, error } = await createCharacter.mutateAsync({
        ...createCharacterParams,
      });

      console.log(character);

      if (!character) {
        throw new Error(`Failed to create character: ${JSON.stringify(error)}`);
      }

      navigate(`/character/${character.id}/`);
    } catch (e) {
      setLoading(false);
      console.error(e);
      try {
        const parsedError = parseMutationError(e);
        setErrors(parsedError);
        toast({
          title: "Invalid Fields",
          description:
            "Some of the fields above are invalid. Please correct the errors and try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } catch (e) {
        toast({
          title: "Error Creating Character",
          description:
            (e as Error | undefined)?.message ??
            "Something went wrong. Please try again later or contact support.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Button
      variant="primary"
      onClick={() => create()}
      isLoading={loading}
      isDisabled={loading}
      size={"sm"}
    >
      Create Character
    </Button>
  );
};

export default CreateCharacterMenu;
