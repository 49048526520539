import React, { Suspense } from "react";
import {
  Box,
  Button,
  Link as ChakraLink,
  Circle,
  Flex,
  IconButton,
  Input,
  Spinner,
  Text,
} from "@chakra-ui/react";
import {
  FiChevronDown,
  FiMessageCircle,
  FiRefreshCw,
  FiSend,
  FiUser,
} from "react-icons/fi";
import ReactMarkdown from "react-markdown";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

import CharacterAvatar from "~/ui/components/character/CharacterAvatar";
import useCharacter from "~/ui/hooks/useCharacter.hook";
import useChat from "~/ui/hooks/useChat.hook";
import useUser from "~/ui/hooks/useUser.hook";
import { Content, Header } from "~/ui/layouts/Page.layout";
import ChatDetailsPageSuspense from "~/ui/suspense/ChatDetailsPage.suspense";
import useIsAuthenticated from "../../ui/hooks/useIsAuthenticated.hook";

const ChatLayoutComponent: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { isAuthenticated } = useIsAuthenticated();
  const { chatId } = useParams();

  const { createChat } = useChat();

  const { chat, createTurns, streamTurn, getLatestChat, regenerateTurn } =
    useChat(chatId ?? "");

  const characterId = chat?.characterId;

  const { character } = useCharacter(characterId ?? "");

  const messagesEndRef = React.useRef<HTMLDivElement>(null);
  const [messageVersions, setMessageVersions] = React.useState<
    Record<string, string[]>
  >({});
  const [currentVersions, setCurrentVersions] = React.useState<
    Record<string, number>
  >({});

  if (!isAuthenticated) {
    return (
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Text>You must be logged in to chat with characters.</Text>
      </Flex>
    );
  }

  const [showScrollButton, setShowScrollButton] = React.useState(true);
  const chatContainerRef = React.useRef<HTMLDivElement | null>(null);

  const setRef = React.useCallback((node: HTMLDivElement | null) => {
    if (node) {
      chatContainerRef.current = node;
      node.addEventListener("scroll", handleScroll, { passive: true });
    }
  }, []);

  const [regeneratingMessageId, setRegeneratingMessageId] = React.useState<
    string | null
  >(null);

  const handleNewChat = async () => {
    try {
      const response = await createChat.mutateAsync({
        characterId: characterId ?? "",
      });
      if (response.chat) {
        navigate(`/chat/${response.chat.id}`);
      }
    } catch (error) {
      console.error("Failed to create new chat:", error);
    }
  };

  const handleRegenerateMessage = async (turnId: string) => {
    if (!chat) return;

    setMessageVersions((prev) => ({
      ...prev,
      [turnId]: [...(prev[turnId] ?? []), ""],
    }));

    setCurrentVersions((prev) => ({
      ...prev,
      [turnId]: (prev[turnId] ?? 0) + 1,
    }));

    setRegeneratingMessageId(turnId);

    try {
      await regenerateTurn(turnId);
      setTimeout(() => scrollToMessage(turnId), 100);
    } catch (error) {
      console.error("Error regenerating message:", error);
    } finally {
      setRegeneratingMessageId(null);
    }
  };

  const [message, setMessage] = React.useState("");

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
      handleScroll();
    }
  };

  const scrollToMessage = (id: string) => {
    const messageElement = document.getElementById(id);
    if (messageElement) {
      messageElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleScroll = React.useCallback(() => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        chatContainerRef.current;
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 20;
      setShowScrollButton(!isNearBottom);

      const chatContainer = chatContainerRef.current;
      chatContainer.addEventListener("scroll", handleScroll);
    }
  }, []);

  React.useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      const handleScrollEvent = () => {
        handleScroll();
      };
      chatContainer.addEventListener("scroll", handleScrollEvent);
    }
  }, [handleScroll]);

  React.useEffect(() => {
    if (chat?.turns[chat.turns.length - 1]?.state === "CREATED") {
      scrollToBottom();
    }
  }, [chat?.turns]);

  // console.log("ChatPage character", character);
  // Extract characterId from the chat object

  if (!chat) {
    return (
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  React.useEffect(() => {
    scrollToBottom();
  }, [chat]);

  // console.log("ChatPage chat", chat);

  // const fetchLatestChat = React.useCallback(async () => {
  //   try {
  //     const latestChat = await getLatestChat.mutateAsync({
  //       characterId: characterId ?? "",
  //     });
  //     if (latestChat.chat) {
  //       setChatId(latestChat.chat.id);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching latest chat:", error);
  //   }
  // }, [characterId]);

  // React.useEffect(() => {
  //   void fetchLatestChat();
  // }, [fetchLatestChat]);

  return (
    <Flex flexDirection="column" height="100vh" width="100%" overflow="hidden">
      <Header
        padding={{ base: "0 16px", md: "0 80px" }}
        height="96px"
        borderBottom="0px"
        position="sticky"
        top="0"
        backgroundColor="gray.1100"
        zIndex="3"
      >
        <Box display="flex" alignItems="center">
          <ChakraLink as={RouterLink} to={`/character/${characterId}`}>
            <CharacterAvatar character={character} size="md" />
          </ChakraLink>
          <Box width="12px" />
          <Box>
            <ChakraLink
              as={RouterLink}
              to={`/character/${characterId}`}
              _hover={{ textDecoration: "none" }}
            >
              <Text variant="16-med">{character?.name}</Text>
              <Text variant="14-reg" color="gray.400">
                {chat.id}
              </Text>
            </ChakraLink>
          </Box>
        </Box>
        <Box>
          <Button
            variant="primary"
            size="sm"
            width="100%"
            leftIcon={<FiMessageCircle />}
            onClick={handleNewChat}
            isLoading={createChat.isLoading}
          >
            New Chat
          </Button>
        </Box>
      </Header>
      <Flex
        flexDirection="column"
        flex="1"
        overflowY="auto"
        ref={chatContainerRef}
        position="relative"
        sx={{
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-track": {
            width: "8px",
            background: "gray.800",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray.600",
            borderRadius: "60px",
          },
          scrollbarWidth: "thin",
          scrollbarColor:
            "var(--chakra-colors-gray-800) var(--chakra-colors-gray-900)",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          marginTop="32px"
          justifyContent="center"
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <ChakraLink as={RouterLink} to={`/character/${characterId}`}>
              <CharacterAvatar character={character} size="lg" />
            </ChakraLink>
            <Box width="12px" height="16px" />
            <ChakraLink
              as={RouterLink}
              to={`/character/${characterId}`}
              _hover={{ textDecoration: "none" }}
            >
              <Text variant="16-med">{character.name}</Text>
              <Text variant="14-reg" color="gray.400">
                {character.title}
              </Text>
            </ChakraLink>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="12px"
          width="100%"
          maxWidth="704px"
          margin="0 auto"
          padding={{ base: "16px", md: "32px" }}
        >
          {chat.turns
            .filter(({ role }) => role !== "system")
            .map(({ role, content, id, state, versions }, index) => {
              const isUser = role === "user";
              const isRegenerating = state === "REGENERATING";
              const isRegenerated = state === "REGENERATED";
              const isRegenerate = state === "REGENERATE";
              const isFailed = state === "REGENERATION_FAILED";
              const isCreated = state === "CREATED";
              const isStreaming =
                state === "IN_PROGRESS" ||
                (state === "COMPLETED" && content === null);
              const isCompleted =
                state === "COMPLETED" || state === "REGENERATED";

              const userIcon = (
                <Circle bg="purple.300" size="28px">
                  <FiUser color="white" size="14px" />
                </Circle>
              );

              const versionArray = versions ?? [];
              const currentVersion =
                currentVersions[id] ?? versionArray.length - 1;

              // Skip rendering for turns with null content (except for the last turn which might be streaming)
              if (
                content === null &&
                !isStreaming &&
                (isRegenerating || isRegenerate) &&
                versionArray.length === 0
              )
                return null;

              return (
                <Flex
                  key={id}
                  id={id}
                  flexDirection="column"
                  width="100%"
                  padding="8px 4px"
                >
                  <Flex
                    justifyContent={isUser ? "flex-end" : "flex-start"}
                    width="100%"
                  >
                    <Flex
                      flexDirection="column"
                      justifyContent={isUser ? "flex-end" : "flex-start"}
                    >
                      <Flex justifyContent={isUser ? "flex-end" : "flex-start"}>
                        {role === "assistant" ? (
                          <CharacterAvatar
                            character={character}
                            size="sm"
                            fontSize="10px"
                          />
                        ) : null}
                        <Text
                          variant="14-semi"
                          alignSelf={isUser ? "flex-end" : "flex-start"}
                          margin="4px 6px"
                        >
                          {isUser ? user?.firstName : character.name}
                        </Text>
                        {isUser ? userIcon : null}
                      </Flex>
                      <Box
                        maxWidth="36rem"
                        bg={isUser ? "gray.800" : "gray.900"}
                        color="gray.200"
                        borderRadius="16px"
                        p={4}
                        my={1}
                        margin="0 28px"
                      >
                        {isRegenerating ? (
                          <Flex alignItems="center" justifyContent="center">
                            <Text variant="16-reg" lineHeight="1.6">
                              {content ? (
                                content
                              ) : (
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Spinner size="md" />
                                </Flex>
                              )}
                            </Text>
                          </Flex>
                        ) : isStreaming ? (
                          <Flex alignItems="center" justifyContent="center">
                            {content ?? ""}
                          </Flex>
                        ) : isFailed ||
                          (!content && !isCreated && !isRegenerate) ? (
                          <Text
                            variant="16-reg"
                            lineHeight="1.6"
                            color="red.400"
                          >
                            Failed to generate response, try again.
                          </Text>
                        ) : (
                          <Text variant="16-reg" lineHeight="1.6">
                            <ReactMarkdown>
                              {versionArray[currentVersion] ?? content ?? ""}
                            </ReactMarkdown>
                          </Text>
                        )}
                        {isCreated && (
                          <Flex alignItems="center" justifyContent="center">
                            <Spinner size="md" />
                          </Flex>
                        )}
                        {isRegenerate && (
                          <Flex alignItems="center" justifyContent="center">
                            <Spinner size="md" />
                          </Flex>
                        )}
                      </Box>
                    </Flex>
                  </Flex>
                  {!isUser && (
                    <Flex
                      justifyContent="flex-start"
                      ml="28px"
                      mt="2"
                      alignItems="center"
                    >
                      <Button
                        size="xs"
                        variant="ghost"
                        onClick={() => handleRegenerateMessage(id)}
                        isLoading={regeneratingMessageId === id}
                        p={2}
                        minW="14px"
                      >
                        <FiRefreshCw size="12px" />
                      </Button>
                      {isRegenerated && versionArray.length > 1 && (
                        <>
                          <Button
                            size="xs"
                            variant="ghost"
                            onClick={() =>
                              setCurrentVersions((prev) => ({
                                ...prev,
                                [id]:
                                  (currentVersion - 1 + versionArray.length) %
                                  versionArray.length,
                              }))
                            }
                            ml={1}
                          >
                            ←
                          </Button>
                          <Text fontSize="xs" ml={2}>
                            {currentVersion + 1}/{versionArray.length}
                          </Text>
                          <Button
                            size="xs"
                            variant="ghost"
                            onClick={() =>
                              setCurrentVersions((prev) => ({
                                ...prev,
                                [id]:
                                  (currentVersion + 1) % versionArray.length,
                              }))
                            }
                            ml={1}
                          >
                            →
                          </Button>
                        </>
                      )}
                    </Flex>
                  )}
                </Flex>
              );
            })}
          <div ref={messagesEndRef} />
          {showScrollButton && (
            <IconButton
              icon={<FiChevronDown />}
              isRound
              size="md"
              position="fixed"
              bottom="110px"
              right="10vw"
              transform="translateX(50%)"
              onClick={scrollToBottom}
              aria-label="Scroll to bottom"
              zIndex="3"
            />
          )}
        </Box>
      </Flex>
      <Box
        position="sticky"
        bottom="0"
        display="flex"
        justifyContent="center"
        width="100%"
        padding={{ base: "16px", md: "32px" }}
        backgroundColor="gray.1100"
      >
        <Box position="relative" width="100%" maxWidth="660px">
          <form
            onSubmit={async (e: React.FormEvent) => {
              e.preventDefault();

              if (message.trim() === "") return;
              const { turns } = await createTurns.mutateAsync({
                turns: [
                  {
                    chatId: chat.id,
                    characterId: chat.characterId,
                    role: "user",
                    content: message,
                    state: "COMPLETED",
                    versions: [],
                  },
                  {
                    chatId: chat.id,
                    characterId: chat.characterId,
                    role: "assistant",
                    content: null,
                    state: "CREATED",
                    versions: [],
                  },
                ],
              });
              setMessage("");

              // console.log("Scrolling to bottom");
              scrollToBottom();

              if (!turns || turns.length === 0) return;
              const lastTurn = turns[turns.length - 1];
              if (!lastTurn) return;

              streamTurn(lastTurn.id);
              // setTimeout(() => scrollToMessage(lastTurn.id), 100);
              scrollToBottom();
              setTimeout(scrollToBottom, 100);
            }}
          >
            <Input
              autoFocus
              placeholder="Type your message here..."
              size="lg"
              width="100%"
              backgroundColor="gray.900"
              borderRadius="24px"
              border="1px solid"
              borderColor="gray.800"
              padding="0 16px 2px"
              height="50px"
              color="white"
              fontSize="16px"
              fontWeight="400"
              lineHeight="24px"
              letterSpacing="0.01em"
              maxWidth="100%"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <Button
              type="submit"
              zIndex="100"
              position="absolute"
              right="5px"
              top="5px"
              variant="primary"
              size="sm"
              borderRadius="50%"
              border="1px solid"
              borderColor="gray.800"
              height="40px"
              width="40px"
              isLoading={createTurns.isPending}
            >
              <FiSend />
            </Button>
          </form>
          <Text variant="12-reg" color="gray.400" textAlign="center" mt={2}>
            Remember: Everything characters say are made up!
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default function ChatPage() {
  return (
    <Suspense fallback={<ChatDetailsPageSuspense />}>
      <ChatLayoutComponent />
    </Suspense>
  );
}
