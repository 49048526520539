import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

import type {
  AuthenticationStrategy,
  RegisterUserParams,
} from "@charry/models";
import { zAuthenticationStrategy } from "@charry/models";

import GoogleLogo from "~/assets/google-button.png";
import PosthogEvent from "~/constants/PosthogEvent.constants";
import AuthUtil from "~/lib/auth";
import parseMutationError from "~/lib/parseMutationError";
import { withSuspense } from "~/ui/hoc/withSuspense";
import { useAppConfig } from "~/ui/hooks/useAppConfig.hook";
import useTracking from "~/ui/hooks/useTracking.hook";
import MaintenanceModeLayout from "~/ui/layouts/MaintenanceMode.layout";
import ERROR_MESSAGES from "../../../utils/src/errors";
import PasswordInput from "../ui/components/PasswordInput";
import Input from "../ui/elements/Input";
import useUser from "../ui/hooks/useUser.hook";

const RegisterPage = () => {
  const { track } = useTracking();
  const { isTauri: _ } = useAppConfig();
  const navigate = useNavigate();
  const toast = useToast();
  const { registerUserParams, setRegisterUserParams, register, authenticate } =
    useUser();
  const [errors, setErrors] = React.useState<Record<string, unknown>>({});
  const [turnstileVerificationError, setTurnstileVerificationError] =
    useState(false);
  const [loading, setLoading] = React.useState(false);
  const [turnstileToken, _setTurnstileToken] = useState<string | undefined>();

  const [confirmPassword, setConfirmPassword] = useState("");

  const setParams = (key: keyof RegisterUserParams, value: string) => {
    setRegisterUserParams((prev) => ({ ...prev, [key]: value }));
    setErrors((prev) => ({ ...prev, [key]: null }));
  };

  const registerUser = useCallback(
    async (strategy?: AuthenticationStrategy) => {
      setLoading(true);
      setTurnstileVerificationError(false);
      try {
        track({
          event: PosthogEvent.AuthRegister,
        });

        let result;
        if (strategy) {
          result = await AuthUtil.startOAuthFlow(strategy);
        } else {
          if (registerUserParams.password !== confirmPassword) {
            setErrors((prev) => ({
              ...prev,
              confirmPassword: {
                message: "Passwords do not match! Try again.",
              },
            }));
            toast({
              title: "Error",
              description: "Passwords do not match! Try again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setLoading(false);
            return;
          }
          result = await register.mutateAsync({
            ...registerUserParams,
            turnstileToken,
          });
        }

        console.log("result", result);

        const { user, token, error } = result;

        console.log("result", result); // this never gets called if using oauth?

        // // Check if password and confirm password match
        // if (registerUserParams.password !== confirmPassword) {
        //   setErrors((prev) => ({
        //     ...prev,
        //     confirmPassword: { message: "Passwords do not match! Try again." },
        //   }));
        //   toast({
        //     title: "Error",
        //     description: "Passwords do not match! Try again.",
        //     status: "error",
        //     duration: 5000,
        //     isClosable: true,
        //   });
        //   setLoading(false);
        //   return;
        // }

        /**
         * OAuth can return an error here, which we throw
         * and catch below to pop a toast
         */
        if (error) {
          throw error;
        }

        if (!token || !user) {
          throw new Error("Failed to register");
        }

        /**
         * Set the params to the user's email and password
         * so they appear in the form while the redirect is loading
         */
        setRegisterUserParams({
          email: user.email,
          password: registerUserParams.password || user.email,
          firstName: user.firstName,
          lastName: user.lastName,
        });

        /**
         * Set token and initialize user
         */
        authenticate({ user, token });

        if (token) {
          navigate("/dashboard/overview");
        }

        if (user.emailVerificationRequired) {
          navigate("/verify-email");
        } else {
          navigate("/dashboard/overview");
        }
      } catch (e: unknown) {
        const e1 = e as Error;
        setLoading(false);
        try {
          const message = e1.message;
          if (message === ERROR_MESSAGES.TURNSTILE_TOKEN_VERIFICATION_FAILED) {
            setTurnstileVerificationError(true);
            toast({
              title: "CAPTCHA Challenge Error",
              description:
                "Our automated CAPTCHA challenge failed. Pleas refresh and try again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          } else {
            const parsedError = parseMutationError(e1);
            setErrors(parsedError);
            toast({
              title: "Invalid Fields",
              description:
                "Some of the fields above are invalid. Please correct the errors and try again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        } catch (e2) {
          toast({
            title: "Error Registering",
            description: e1.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    },
    [
      register,
      registerUserParams,
      setRegisterUserParams,
      authenticate,
      turnstileToken,
      toast,
      track,
      navigate,
      confirmPassword,
    ],
  );

  return (
    <MaintenanceModeLayout>
      <Box
        backgroundColor="gray.1000"
        height="100vh"
        width="100vw"
        display="flex"
        justifyContent="center"
      >
        <Flex
          position="relative"
          top={{ base: "0", md: "96px" }}
          width={{ base: "100%", md: "420px" }}
          height="fit-content"
          flexDirection="column"
          alignItems="center"
          borderRadius="8px"
          boxSizing="border-box"
        >
          <Text variant="30-bold" color="white" marginLeft="16px">
            Sign up for an account
          </Text>
          <Box height="12px" />
          <Text variant="16-reg" color="gray.400" marginLeft="16px">
            Enter your details below to get started.
          </Text>
          <Box height="32px" />
          <form
            style={{ width: "100%" }}
            onSubmit={(e) => {
              e.preventDefault();
              void registerUser();
            }}
          >
            <Stack width="100%" spacing="20px">
              <Flex>
                <Input
                  id="firstName"
                  label="First Name"
                  type="text"
                  width="100%"
                  size="lg"
                  value={registerUserParams.firstName}
                  placeholder="First Name"
                  onChange={(e) =>
                    setParams("firstName", e.currentTarget.value)
                  }
                  isInvalid={errors.firstName ? true : false}
                  autoFocus
                />
                <Box width="12px" flexShrink="0" />
                <Input
                  id="lastName"
                  label="Last Name"
                  type="text"
                  width="100%"
                  size="lg"
                  value={registerUserParams.lastName}
                  placeholder="Last Name"
                  onChange={(e) => setParams("lastName", e.currentTarget.value)}
                  isInvalid={errors.lastName ? true : false}
                />
              </Flex>
              <Input
                id="email"
                label="Email"
                type="email"
                width="100%"
                size="lg"
                value={registerUserParams.email}
                placeholder="Enter your email"
                onChange={(e) => setParams("email", e.currentTarget.value)}
                isInvalid={errors.email ? true : false}
              />
              <PasswordInput
                id="password"
                label="Password"
                width="100%"
                size="lg"
                value={registerUserParams.password}
                placeholder="••••••••"
                onChange={(e) => setParams("password", e.currentTarget.value)}
                isInvalid={errors.password ? true : false}
              />
              <PasswordInput
                id="confirmPassword"
                label="Confirm Password"
                width="100%"
                size="lg"
                value={confirmPassword}
                placeholder="••••••••"
                onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                isInvalid={errors.confirmPassword ? true : false}
              />
            </Stack>

            <Stack width="100%" spacing="0">
              <Box height="24px" />
              {loading ? (
                <Flex alignItems="center" justify="center" height="104px">
                  <Spinner size="lg" />
                </Flex>
              ) : (
                <>
                  <Button
                    variant="primary"
                    size="lg"
                    width="100%"
                    type="submit"
                  >
                    Sign Up
                  </Button>
                  <Box height="16px" />
                  <Flex alignItems="center">
                    <Box
                      onClick={() =>
                        registerUser(zAuthenticationStrategy.Enum.Google)
                      }
                      _hover={{ cursor: "pointer" }}
                      margin="0 auto"
                    >
                      <Image
                        alt="Google Logo"
                        src={GoogleLogo}
                        width="100%"
                        height="46"
                      />
                    </Box>
                  </Flex>
                  <Box height="32px" />
                  <Text variant="14-reg" color="white" align="center">
                    Already have an account?&nbsp;
                    <Link to="/login">
                      <Text as="span" variant="14-bold" color="#2970FF">
                        Login
                      </Text>
                    </Link>
                  </Text>
                </>
              )}
              {turnstileVerificationError && (
                <Text marginTop="32px" color="red.400" textAlign="center">
                  CAPTCHA verification failed. Please refresh the page and try
                  again. If you have a VPN, please consider disabling it.
                </Text>
              )}
            </Stack>
          </form>
        </Flex>
      </Box>
    </MaintenanceModeLayout>
  );
};

export default withSuspense(RegisterPage);
