import React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FiCheck, FiMail, FiUser } from "react-icons/fi";
import { RxDiscordLogo } from "react-icons/rx";

import { zAuthenticationStrategy } from "@charry/models";

import AuthUtil from "~/lib/auth";
import { api } from "~/lib/trpc";
import useUser from "~/ui/hooks/useUser.hook";
import SettingsLayout from "~/ui/layouts/Settings.layout";

const AccountSettingsPage: React.FC = () => {
  const { user, refreshUser } = useUser();
  const toast = useToast();

  const unlinkDiscordMutation = api.user.unlinkDiscordAccount.useMutation();

  const handleLinkDiscord = async () => {
    try {
      const result = await AuthUtil.startOAuthFlow(
        zAuthenticationStrategy.Enum.Discord,
      );

      if (result.error) {
        throw new Error(result.error.message);
      }

      if (result.user) {
        await refreshUser();
        toast({
          title: "Success",
          description: "Discord account linked successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error linking Discord account:", error);
      toast({
        title: "Error",
        description: "Failed to link Discord account. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUnlinkDiscord = async () => {
    try {
      await unlinkDiscordMutation.mutateAsync();
      await refreshUser();
      toast({
        title: "Success",
        description: "Discord account unlinked successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error unlinking Discord account:", error);
      toast({
        title: "Error",
        description: "Failed to unlink Discord account. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <SettingsLayout
      title="Account Settings"
      description="Manage your Charry account settings"
    >
      <VStack spacing={6} align="stretch">
        <Divider />
        <Box>
          <Heading as="h2" size="md" mb={2}>
            <Flex alignItems="center">
              <FiUser />
              <Text ml={2}>Account Information</Text>
            </Flex>
          </Heading>
          <Text>Name: {user?.firstName ?? "Not set"}</Text>
          <Text>Username: {user?.username ?? "Not set"}</Text>
          <Text>Email: {user?.email}</Text>
        </Box>

        <Divider />

        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <FiMail size={20} />
              <Text fontSize="lg" ml={2}>
                Email Verification
              </Text>
            </Flex>
            {user?.emailVerifiedAt ? (
              <Flex alignItems="center" color="green.500">
                <FiCheck />
                <Text ml={2}>Verified</Text>
              </Flex>
            ) : (
              <Link href={"/verify-email"} target="_blank">
                <Button variant="outline">Verify Email</Button>
              </Link>
            )}
          </Flex>
        </Box>

        <Divider />

        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <RxDiscordLogo size={20} />
              <Text fontSize="lg" ml={2}>
                Discord Connection
              </Text>
            </Flex>
            {user?.discordId ? (
              <Flex flexDirection="column" alignItems="flex-end">
                <Flex alignItems="center" color="green.500">
                  <FiCheck />
                  <Text ml={2}>Connected</Text>
                  <Text ml={2} fontSize="sm">
                    "{user.discordUsername}"
                  </Text>
                </Flex>
                <Button
                  mt={2}
                  onClick={handleUnlinkDiscord}
                  variant="outline"
                  size="sm"
                >
                  Unlink Discord
                </Button>
              </Flex>
            ) : (
              <Button variant="outline" onClick={handleLinkDiscord}>
                Link Discord
              </Button>
            )}
          </Flex>
        </Box>
      </VStack>
    </SettingsLayout>
  );
};

export default AccountSettingsPage;
