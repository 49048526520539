import type { AvatarProps } from "@chakra-ui/react";
import React from "react";
import { Avatar } from "@chakra-ui/react";

import type { Character } from "@charry/models";

type CharacterAvatarProps = {
  character: Character;
  onClick?: () => void;
  size?: string;
} & AvatarProps;

const CharacterAvatar: React.FC<CharacterAvatarProps> = ({
  character,
  onClick,
  size = "md",
  ...rest
}) => {
  return (
    <Avatar
      name={character.name.split(" ")[0]}
      src={character.imageUrl ?? ""}
      onClick={onClick}
      backgroundColor="purple.300"
      color="black"
      size={size}
      {...rest}
    />
    // <FiUser color="white" size="28px" />
  );
};

export default CharacterAvatar;
