import React from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from "@chakra-ui/react";
import { FiMenu, FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useLockedBody } from "usehooks-ts";

import CharryLogo from "~/assets/charry.svg";
import DiscordLogo from "~/assets/discord.svg";
import GitHubLogo from "~/assets/github.svg";
import TwitterLogo from "~/assets/twitter.svg";
import {
  CAREERS_LINK,
  DISCORD_INVITE_LINK,
  DOCUMENTATION_LINK,
  TWITTER_LINK,
} from "~/lib/const";
import useIsAuthenticated from "~/ui/hooks/useIsAuthenticated.hook";
import { Header } from "~/ui/layouts/Page.layout";

const nav = [
  // {
  //   title: "Careers",
  //   link: CAREERS_LINK,
  //   isExternal: true,
  // },
  // {
  //   title: "Documentation",
  //   link: DOCUMENTATION_LINK,
  //   isExternal: true,
  // },
  {
    image: <Image src={GitHubLogo} width="24px" height="24px" />,
    title: "GitHub",
    link: "https://github.com/context-labs",
    isExternal: true,
  },
  {
    image: <Image src={DiscordLogo} width="24px" height="24px" />,
    title: "Discord",
    link: DISCORD_INVITE_LINK,
    isExternal: true,
  },
  {
    image: <Image src={TwitterLogo} width="24px" height="24px" />,
    title: "Twitter",
    link: TWITTER_LINK,
    isExternal: true,
  },
];

const Desktop: React.FC = () => {
  const { isAuthenticated } = useIsAuthenticated();

  return (
    <Flex
      alignItems="center"
      justifyContent="flex-end"
      display={{ base: "none", md: "flex" }}
    >
      <Grid
        templateColumns={`repeat(${3}, 1fr)`}
        gap={6}
        display={{ base: "none", md: "flex" }}
        justifyContent="center"
        marginRight="32px"
      >
        {nav.map((item, index) => {
          return (
            <GridItem key={index}>
              <Link to={item.link}>
                {item.image ? (
                  <Box>{item.image}</Box>
                ) : (
                  <Box fontSize="14px">{item.title}</Box>
                )}
              </Link>
            </GridItem>
          );
        })}
      </Grid>
      {isAuthenticated ? (
        <Link to="/dashboard/overview">
          <Button
            variant="secondary"
            size="sm"
            fontSize="14px"
            fontWeight="500"
          >
            Login / Register
          </Button>
        </Link>
      ) : (
        <>
          <Link to="/login">
            <Button variant="ghost" size="sm" fontSize="14px" fontWeight="500">
              Login
            </Button>
          </Link>
          <Box width="8px" />
          <Link to="/register">
            <Button
              variant="secondary"
              size="sm"
              fontSize="14px"
              fontWeight="500"
            >
              Register
            </Button>
          </Link>
        </>
      )}
    </Flex>
  );
};

const Mobile: React.FC = () => {
  const { isAuthenticated } = useIsAuthenticated();
  const [locked, setLocked] = useLockedBody(false, "root");

  return (
    <Box display={{ base: "block", md: "none" }}>
      {locked ? (
        <FiX fontSize="24px" onClick={() => setLocked(false)} />
      ) : (
        <FiMenu fontSize="24px" onClick={() => setLocked(true)} />
      )}
      {locked ? (
        <Box
          position="absolute"
          top="160px"
          left="0px"
          zIndex="100"
          height="100vh"
          width="100vw"
          backgroundColor="black"
          padding="24px"
          display="border-box"
        >
          <Grid templateColumns="repeat(1, auto)" gap={8}>
            {nav.map((item, index) => {
              return (
                <GridItem key={index}>
                  <Link to={item.link} onClick={() => setLocked(false)}>
                    <Text
                      key={index}
                      variant="24-reg"
                      color="gray.300"
                      _hover={{
                        cursor: "pointer",
                        color: "white",
                        transition: "0.1s",
                      }}
                    >
                      {item.title}
                    </Text>
                  </Link>
                </GridItem>
              );
            })}
            {isAuthenticated ? (
              <GridItem>
                <Link to="/dashboard/overview">
                  <Text
                    variant="24-reg"
                    color="gray.300"
                    _hover={{
                      cursor: "pointer",
                      color: "white",
                      transition: "0.1s",
                    }}
                  >
                    Login / Register
                  </Text>
                </Link>
              </GridItem>
            ) : (
              <>
                <GridItem>
                  <Link to="/login">
                    <Text
                      variant="24-reg"
                      color="gray.300"
                      _hover={{
                        cursor: "pointer",
                        color: "white",
                        transition: "0.1s",
                      }}
                    >
                      Login
                    </Text>
                  </Link>
                </GridItem>
                <GridItem>
                  <Link to="/register">
                    <Text
                      variant="24-reg"
                      color="gray.300"
                      _hover={{
                        cursor: "pointer",
                        color: "white",
                        transition: "0.1s",
                      }}
                    >
                      Register
                    </Text>
                  </Link>
                </GridItem>
              </>
            )}
          </Grid>
        </Box>
      ) : null}
    </Box>
  );
};

const MarketingNavigation: React.FC = () => {
  return (
    <Header width={{ base: "100%", md: "1280px" }} height="80px">
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Link to="/">
          <Flex alignItems="center">
            <Image
              alt="Charry Logo"
              src={CharryLogo}
              width="32px"
              height="32px"
            />
            <Box width="12px" />
            <Text variant="24-bold">Charry.ai</Text>
          </Flex>
        </Link>
        <Mobile />
        <Desktop />
      </Flex>
    </Header>
  );
};

export default MarketingNavigation;
