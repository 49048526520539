import React from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";

import type {
  CharacterVisibility,
  CreateCharacterParams,
  UpdateCharacterParams,
} from "@charry/models";

import type useCharacter from "~/ui/hooks/useCharacter.hook";
import type useCharacterList from "~/ui/hooks/useCharacterList.hook";
import Card from "~/ui/elements/Card";
import Label from "~/ui/elements/Label";

type VisibilityInfo = {
  name: string;
  type: CharacterVisibility;
  description: string;
};

type VisibilityRowParams = {
  type: VisibilityInfo;
  selected: boolean;
  onClick: () => void;
};

const VisibilityRow: React.FC<VisibilityRowParams> = ({
  type,
  selected,
  onClick,
}) => {
  return (
    <Card padding="12px" onClick={onClick} isSelected={selected}>
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Flex direction="row" alignContent="center">
          <Box marginLeft="8px">
            <Text variant="16-reg" color="white" noOfLines={1}>
              {type.name}
            </Text>
            <Flex direction="row" alignContent="center">
              <Text variant="14-reg" color="gray.400">
                {type.description}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          marginRight="16px"
          width="24px"
        >
          {selected ? (
            <CheckCircleIcon color="blue.500" fontSize="24px" />
          ) : null}
        </Box>
      </Flex>
    </Card>
  );
};

type Params = CreateCharacterParams | UpdateCharacterParams;

type CharacterVisibilitySelectProps = {
  params: Params;
  setParams:
    | ReturnType<typeof useCharacterList>["setCreateCharacterParams"]
    | ReturnType<typeof useCharacter>["setUpdateCharacterParams"];
  errors: { visibility?: CharacterVisibility };
};

const CharacterVisibilitySelect: React.FC<CharacterVisibilitySelectProps> = ({
  params,
  setParams,
}) => {
  const types: VisibilityInfo[] = [
    {
      name: "Public",
      type: "Public",
      description: "Available for others to discover and chat with.",
    },
    {
      name: "Unlisted",
      type: "Unlisted",
      description: "Available for others to chat with via link.",
    },
    {
      name: "Private",
      type: "Private",
      description: "Only available to you.",
    },
  ];

  return (
    <Flex direction="column">
      <Label>Visibility</Label>
      {types.map((type, index) => (
        <>
          <VisibilityRow
            key={type.name}
            type={type}
            selected={params.visibility === type.type}
            onClick={() => {
              // @ts-expect-error - need better types here.
              void setParams((prevParams) => ({
                ...prevParams,
                visibility: type.type,
              }));
            }}
          />
          {index === types.length - 1 ? null : <Box height="8px" />}
        </>
      ))}
      <Box height="16px" />
      <Text variant="12-reg" color="gray.400">
        {/* Note: We do not currently support CLI workers on MacOS or Windows. */}
      </Text>
    </Flex>
  );
};

export default CharacterVisibilitySelect;
