import React from "react";
import { Box, Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import CharryLogo from "~/assets/charry.svg";
import {
  DISCORD_INVITE_LINK,
  DOCUMENTATION_LINK,
  MEETING_LINK,
  TELEGRAM_LINK,
  TWITTER_LINK,
} from "~/lib/const";

type FooterProps = unknown;

const Footer: React.FC<FooterProps> = () => {
  const categories = [
    {
      title: "Connect",
      links: [
        {
          title: "Discord",
          link: DISCORD_INVITE_LINK,
        },
        {
          title: "Twitter",
          link: TWITTER_LINK,
        },
      ],
    },
    {
      title: "Product",
      links: [
        // {
        //   title: "Download",
        //   link: "/download",
        // },
        // {
        //   title: "Documentation",
        //   link: DOCUMENTATION_LINK,
        // },
        {
          title: "Login",
          link: "/login",
        },
        {
          title: "Register",
          link: "/register",
        },
        {
          title: "Privacy Policy",
          link: "/privacy-policy",
        },
        {
          title: "Terms of Service",
          link: "/terms",
        },
      ],
    },
    {
      title: "Partners",
      links: [
        {
          title: "Telegram",
          link: TELEGRAM_LINK,
        },
        {
          title: "Book a meeting",
          link: MEETING_LINK,
        },
      ],
    },
  ];

  return (
    <Flex
      justifyContent="center"
      position="relative"
      width="100%"
      borderTop="1px solid"
      borderColor="gray.800"
      backgroundColor="gray.1000"
    >
      <Box width="1000px" padding="48px 16px" position="relative">
        <Grid templateColumns="repeat(4, 1fr)" gap={8}>
          <GridItem>
            <Link to="/">
              <Image alt="Charry Logo" src={CharryLogo} width="48px" />
            </Link>
          </GridItem>
          {categories.map((category, index) => {
            return (
              <GridItem key={index}>
                <Text variant="16-semi">{category.title}</Text>
                <Box height="16px" />
                {category.links.map(({ title, link }, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Link to={link}>
                        <Text
                          key={index}
                          variant="14-reg"
                          color="gray.300"
                          _hover={{
                            cursor: "pointer",
                            color: "white",
                            transition: "0.1s",
                          }}
                        >
                          {title}
                        </Text>
                      </Link>
                      <Box height="16px" />
                    </React.Fragment>
                  );
                })}
              </GridItem>
            );
          })}
        </Grid>
      </Box>
    </Flex>
  );
};

export default Footer;
