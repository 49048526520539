import { z } from "zod";

// Assuming Avatar is imported from another file
import { AvatarSchema_CAI } from "./other.cai";

export const RoomSchema_CAI = z.object({
  external_id: z.string(),
  title: z.string(),
  description: z.string(),
  participants: z.array(
    z
      .object({
        name: z.string(),
        avatar_file_name: z.string(),
      })
      .merge(AvatarSchema_CAI),
  ),
  img_gen_enabled: z.boolean(),
});

export const NameSchema_CAI = z.object({
  ko: z.string().optional(),
  ru: z.string().optional(),
  ja_JP: z.string().optional(),
  zh_CN: z.string().optional(),
});

export const CharacterTranslationsSchema_CAI = z.object({
  name: NameSchema_CAI,
});

export const ChatSchema_CAI = z
  .object({
    chat_id: z.string(),
    create_time: z.string().transform((str) => new Date(str)),
    creator_id: z.string(),
    character_id: z.string(),
    state: z.string(),
    type: z.string(),
    visibility: z.string(),
    character_name: z.string(),
    character_visibility: z.string(),
    character_translations: CharacterTranslationsSchema_CAI,
    default_voice_id: z.string().nullish(),
    avatar_file_name: z.string().nullish(),
  })
  .merge(AvatarSchema_CAI);

// Type definitions
export type Room_CAI = z.infer<typeof RoomSchema_CAI>;
export type Name_CAI = z.infer<typeof NameSchema_CAI>;
export type CharacterTranslations_CAI = z.infer<
  typeof CharacterTranslationsSchema_CAI
>;
export type Chat_CAI = z.infer<typeof ChatSchema_CAI>;
