import React, { Suspense } from "react";
import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { FiAperture } from "react-icons/fi";
import { Link } from "react-router-dom";

import CharacterCard from "~/ui/components/character/CharacterCard";
import NoContentCard from "~/ui/components/NoContentCard";
import SearchInput from "~/ui/elements/SearchInput";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import useMyCharacter from "~/ui/hooks/useMyCharacter.hook";
import useUser from "~/ui/hooks/useUser.hook";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";
import CharacterLayoutSuspense from "~/ui/suspense/CharacterLayout.suspense";

const CharactersListPageComponent = () => {
  const isMobile = useIsMobile();
  const { user } = useUser();
  const [search, setSearch] = React.useState("");
  const {
    characterList,
    paginatedCharacterList,
    totalPages,
    currentPage,
    setCurrentPage,
  } = useMyCharacter(user?._id);
  const filtered = paginatedCharacterList.filter((w) =>
    JSON.stringify(w).toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <>
      <Header>
        <Flex alignItems="center" flex="1">
          <Title>My Characters</Title>
          <Box width="16px" />
          {!isMobile ? (
            <>
              <InputGroup maxWidth="240px" size="sm">
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.500" />
                </InputLeftElement>
                <SearchInput
                  type="text"
                  value={search}
                  size="sm"
                  placeholder="search characters.."
                  maxWidth={{ base: undefined, md: "240px" }}
                  onChange={(e) => setSearch(e.currentTarget.value)}
                  raw
                />
              </InputGroup>
              <Box width="16px" />
              <Text
                variant="12-reg"
                color="gray.400"
                display="inline"
                whiteSpace="nowrap"
              >
                Showing {filtered.length}&nbsp;of&nbsp;{characterList.length}
              </Text>
            </>
          ) : (
            <>
              <Text
                variant="12-reg"
                color="gray.400"
                display="inline"
                whiteSpace="nowrap"
              >
                {filtered.length}&nbsp;of&nbsp;{characterList.length}
              </Text>
            </>
          )}
        </Flex>
        <Box>
          <Link to="/characters/create">
            <Button variant="primary" size="sm">
              Create Character
            </Button>
          </Link>
        </Box>
      </Header>
      <Content shouldSuspendContent={false}>
        {characterList.length > 50 && (
          <>
            <Box height="24px" />
            <Flex justifyContent="center" alignItems="center">
              <Button
                variant="secondary"
                size="sm"
                onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
                isDisabled={currentPage === 1}
              >
                Previous
              </Button>
              <Text mx={4}>
                {currentPage} of {totalPages}
              </Text>
              <Button
                variant="secondary"
                size="sm"
                onClick={() =>
                  setCurrentPage(Math.min(totalPages, currentPage + 1))
                }
                isDisabled={currentPage === totalPages}
              >
                Next
              </Button>
            </Flex>
          </>
        )}
        <Box height="24px" />
        {characterList.length > 0 ? (
          <>
            {filtered.length > 0 ? (
              <>
                <Grid
                  templateColumns={
                    isMobile ? "repeat(1, 1fr)" : "repeat(3, 1fr)"
                  }
                  gap={6}
                >
                  {filtered.map((character) => {
                    return (
                      <GridItem key={character.id}>
                        <CharacterCard character={character} />
                      </GridItem>
                    );
                  })}
                </Grid>
                <Box height="24px" />
              </>
            ) : (
              <NoContentCard
                Icon={FiAperture}
                Text={() => (
                  <Text>
                    No characters found for search term&quot;{search}&quot;.
                    <br /> Try another search term, or create a new character
                    below
                  </Text>
                )}
                Button={() => (
                  <Link to="/characters/create" style={{ width: "400px" }}>
                    <Button variant="primary" width="100%">
                      Create Character
                    </Button>
                  </Link>
                )}
              />
            )}
          </>
        ) : (
          <NoContentCard
            Icon={FiAperture}
            Text={() => <Text></Text>}
            Button={() => (
              <Link to="/characters/create" style={{ width: "400px" }}>
                <Button variant="primary" width="100%">
                  Create Character
                </Button>
              </Link>
            )}
          />
        )}
        <Box height="200px" />
      </Content>
    </>
  );
};

export default function CharactersListPage() {
  return (
    <Suspense fallback={<CharacterLayoutSuspense />}>
      <CharactersListPageComponent />
    </Suspense>
  );
}
