import { z } from "zod";

import {
  authedServiceRequest,
  serviceRequest,
  serviceResponse,
} from "./BaseService";

/** ******************************************************************************
 *  Constants
 ******************************************************************************* */

// NOTE: This ID is ONLY used in the system table. Other tables which include
// the 'system' user use nano IDs for unique IDs.
export const SYSTEM_STATUS_ID = "system-status";

export const SYSTEM_USER_EMAIL = "system@charry.ai";

/** ******************************************************************************
 *  Types
 ******************************************************************************* */

export const SystemModeSchema = z.enum([
  "Operational",
  "Degraded",
  "Outage",
  "Maintenance",
]);

export const SystemStatusSchema = z.object({
  _id: z.string(),
  mode: SystemModeSchema,
  message: z.string(),
  allowTraffic: z.boolean(),
  lastUpdatedBy: z.string(),
  lastUpdatedAt: z.number(),
  createdAt: z.number(),
});

export const SystemStatusMongoSchema = {
  _id: { type: String, required: true },
  mode: {
    type: String,
    enum: ["Operational", "Degraded", "Outage", "Maintenance"],
    required: true,
  },
  message: { type: String, required: true },
  allowTraffic: { type: Boolean, required: true },
  lastUpdatedBy: { type: String, required: true },
  lastUpdatedAt: { type: Number, required: true },
  createdAt: { type: Number, required: true },
};

export type SystemStatus = z.infer<typeof SystemStatusSchema>;
export type SystemMode = z.infer<typeof SystemModeSchema>;

export type SystemService = {
  get(request: GetSystemStatusRequest): Promise<GetSystemStatusResponse>;
  superSet(
    request: SuperSetSystemStatusRequest,
  ): Promise<SuperSetSystemStatusResponse>;
};

/** ******************************************************************************
 *  Get System Status
 ******************************************************************************* */

export const getSystemStatusParams = z.undefined();

export const getSystemStatusRequest = serviceRequest.merge(
  z.object({
    params: getSystemStatusParams,
  }),
);

export const getSystemStatusResponse = serviceResponse.merge(
  z.object({
    systemStatus: SystemStatusSchema.nullish(),
  }),
);

export type GetSystemStatusParams = z.infer<typeof getSystemStatusParams>;
export type GetSystemStatusRequest = z.infer<typeof getSystemStatusRequest>;
export type GetSystemStatusResponse = z.infer<typeof getSystemStatusResponse>;

/** ******************************************************************************
 *  Super Set System Status
 ******************************************************************************* */

export const superSetSystemStatusParams = z.object({
  mode: SystemModeSchema,
  message: z.string(),
  allowTraffic: z.boolean(),
});

export const superSetSystemStatusRequest = authedServiceRequest.merge(
  z.object({
    params: superSetSystemStatusParams,
  }),
);

export const superSetSystemStatusResponse = serviceResponse.merge(
  z.object({
    systemStatus: SystemStatusSchema.nullish(),
  }),
);

export type SuperSetSystemStatusParams = z.infer<
  typeof superSetSystemStatusParams
>;
export type SuperSetSystemStatusRequest = z.infer<
  typeof superSetSystemStatusRequest
>;
export type SuperSetSystemStatusResponse = z.infer<
  typeof superSetSystemStatusResponse
>;
