import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import { useAtom } from "jotai";

import type { Character } from "@charry/models";

import type { RouterOutput } from "~/lib/trpc";
import { api, trpc } from "~/lib/trpc";
import { CharacterState } from "../jotai/character.jotai";

export default function useCharacterList() {
  const queryClient = useQueryClient();

  const { data: characterListQuery, isLoading } =
    api.character.explore.useQuery(undefined, {
      enabled: true,
      refetchInterval: 60_000,
      /**
       * todo: figure out how to better handle
       * suspense in react query
       * https://github.com/TanStack/query/discussions/6361
       * stupid hack
       */
      staleTime: 0,
    });

  const characterList = characterListQuery?.characters ?? [];

  const setCharacterList = React.useCallback(
    (fn: (current: Character[]) => Character[]) => {
      const queryKey = getQueryKey(api.character.explore, undefined, "query");
      const current =
        queryClient.getQueryData<RouterOutput["character"]["explore"]>(
          queryKey,
        );
      queryClient.setQueryData(queryKey, {
        ...current,
        characters: fn(current?.characters ?? []),
      });
    },
    [queryClient],
  );

  const pageSize = 50;
  const totalPages = Math.ceil(characterList.length / pageSize);
  const [currentPage, setCurrentPage] = React.useState(1);

  const paginatedCharacterList = characterList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  const refreshCharacterList = useMutation({
    mutationKey: ["refreshCharacterList"],
    mutationFn: async () => {
      return await trpc.character.explore.query();
    },
    onSuccess: ({ characters }) => {
      if (!characters) return;

      setCharacterList(() => characters);
    },
  });

  const createCharacter = api.character.create.useMutation({
    onSuccess: ({ character, error }) => {
      if (!character) {
        throw new Error(error?.message ?? "Failed to create character");
      }

      setCharacterList((current) => [character, ...current]);
    },
  });

  const [createCharacterParams, setCreateCharacterParams] = useAtom(
    CharacterState.createCharacterParams,
  );

  return {
    characterList,
    refreshCharacterList,
    setCharacterList,
    paginatedCharacterList,
    totalPages,
    currentPage,
    setCurrentPage,
    createCharacter,
    createCharacterParams,
    setCreateCharacterParams,
    isLoading,
  };
}
