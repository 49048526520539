import posthog from "posthog-js";

import type { FeatureFlag, FeatureFlagKey } from "@charry/utils";

export default function useFlagEnabled<FlagKey extends FeatureFlagKey>(
  flag: FlagKey,
  fallbackValue: boolean,
): FeatureFlag[FlagKey] {
  return (posthog.isFeatureEnabled(flag) ??
    fallbackValue) as FeatureFlag[FlagKey];
}
