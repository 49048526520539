import { z } from "zod";

// Assuming Avatar is imported from another file
import { AvatarSchema_CAI } from "./other.cai";

export const RepliesSchema_CAI = z.object({
  text: z.string(),
  uuid: z.string(),
  id: z.number(),
});

export const ParticipantSchema_CAI = z.object({
  name: z.string(),
});

export const SrcCharSchema_CAI = z
  .object({
    participant: ParticipantSchema_CAI,
    avatar_file_name: z.string().optional(),
  })
  .merge(AvatarSchema_CAI);

export const MessageSchema_CAI = z.object({
  replies: z.array(RepliesSchema_CAI),
  src_char: SrcCharSchema_CAI,
  is_final_chunk: z.boolean(),
  last_user_msg_id: z.number(),
  last_user_msg_uuid: z.string(),
});

export const UserAccountSchema_CAI = z
  .object({
    name: z.string(),
    avatar_type: z.string(),
    onboarding_complete: z.boolean(),
    avatar_file_name: z.string(),
    mobile_onboarding_complete: z.number(),
  })
  .merge(AvatarSchema_CAI);

export const ParticipantsSchema_CAI = z.object({
  user: z.object({
    username: z.string(),
    id: z.number(),
    first_name: z.string(),
    account: UserAccountSchema_CAI.optional(),
    is_staff: z.boolean(),
  }),
  is_human: z.boolean(),
  name: z.string(),
  num_interactions: z.number(),
});

export const MessagesSchema_CAI = z.object({
  deleted: z.boolean(),
  id: z.number(),
  image_prompt_text: z.string(),
  image_rel_path: z.string(),
  is_alternative: z.boolean(),
  responsible_user__username: z.string(),
  src__character__avatar_file_name: z.string(),
  src__is_human: z.boolean(),
  src__name: z.string(),
  src__user__username: z.string(),
  src_char: SrcCharSchema_CAI,
  text: z.string(),
});

export const NewChatSchema_CAI = z.object({
  title: z.string(),
  participants: z.array(ParticipantsSchema_CAI),
  external_id: z.string(),
  created: z.date(),
  last_interaction: z.date(),
  type: z.string(),
  description: z.string(),
  speech: z.string(),
  status: z.string(),
  has_more: z.boolean(),
  messages: z.array(MessagesSchema_CAI),
  id: z.string(),
});

export const AvatarsSchema_CAI = z
  .object({
    name: z.string(),
    avatar_file_name: z.string().optional(),
  })
  .merge(AvatarSchema_CAI);

export const ChatHistorySchema_CAI = z.object({
  title: z.string(),
  participants: z.array(ParticipantsSchema_CAI),
  external_id: z.string(),
  created: z.date(),
  last_interaction: z.date(),
  type: z.string(),
  description: z.string(),
  avatars: z.array(AvatarsSchema_CAI),
  room_img_gen_enabled: z.boolean(),
});

export const HisMessageSchema_CAI = z.object({
  id: z.number(),
  uuid: z.string(),
  text: z.string(),
  src: z.string(),
  tgt: z.string(),
  is_alternative: z.boolean().optional(),
  image_rel_path: z.string(),
  image_prompt_text: z.string(),
  deleted: z.boolean().optional(),
  src__name: z.string(),
  src__user__username: z.string(),
  src__is_human: z.boolean().optional(),
  src__character__avatar_file_name: z.string().optional(),
  src_char: SrcCharSchema_CAI,
  responsible_user__username: z.string().optional(),
});

export const HistorySchema_CAI = z.object({
  external_id: z.string(),
  last_interaction: z.date(),
  created: z.date(),
  msgs: z.array(HisMessageSchema_CAI),
});

export const HisMessagesSchema_CAI = z.object({
  messages: z.array(HisMessageSchema_CAI),
  next_page: z.number(),
  has_more: z.boolean(),
});

export const MigrateSchema_CAI = z.object({
  id: z.string(),
  create_time: z.date(),
  last_update: z.date(),
  status: z.string(),
  properties: z.string(),
});

// Types inferred from the schemas
export type Replies_CAI = z.infer<typeof RepliesSchema_CAI>;
export type Participant_CAI = z.infer<typeof ParticipantSchema_CAI>;
export type SrcChar_CAI = z.infer<typeof SrcCharSchema_CAI>;
export type Message_CAI = z.infer<typeof MessageSchema_CAI>;
export type UserAccount_CAI = z.infer<typeof UserAccountSchema_CAI>;
export type Participants_CAI = z.infer<typeof ParticipantsSchema_CAI>;
export type Messages_CAI = z.infer<typeof MessagesSchema_CAI>;
export type NewChat_CAI = z.infer<typeof NewChatSchema_CAI>;
export type Avatars_CAI = z.infer<typeof AvatarsSchema_CAI>;
export type ChatHistory_CAI = z.infer<typeof ChatHistorySchema_CAI>;
export type HisMessage_CAI = z.infer<typeof HisMessageSchema_CAI>;
export type History_CAI = z.infer<typeof HistorySchema_CAI>;
export type HisMessages_CAI = z.infer<typeof HisMessagesSchema_CAI>;
export type Migrate_CAI = z.infer<typeof MigrateSchema_CAI>;
