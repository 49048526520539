import { Box, Flex, Grid, Skeleton } from "@chakra-ui/react";

import { Content } from "../layouts/Page.layout";
import PageLayoutSuspense from "./PageLayout.suspense";

export default function CharacterLayoutSuspense() {
  return (
    <>
      {/* <PageLayoutSuspense pageTitle="Explore Characters"> */}
      <Content>
        <Box mb={4} mt={4}>
          {/* <Flex flexDirection="column" alignItems="flex-start" mb={4}>
              <Skeleton height="24px" width="150px" mb={2} />
              <Skeleton height="32px" width="200px" />
            </Flex> */}
          <Grid templateColumns={{ base: "1fr", md: "60% 40%" }} gap={2}>
            <Skeleton height="250px" borderRadius="xl" />
            <Flex direction={{ base: "row", md: "column" }} gap={2}>
              <Skeleton height="100px" width="100%" borderRadius="lg" />
              <Skeleton height="100px" width="100%" borderRadius="lg" />
            </Flex>
          </Grid>
        </Box>

        <Box mb={4}>
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <Skeleton height="32px" width="100px" />
            <Flex>
              <Skeleton height="40px" width="40px" mr={2} />
              <Skeleton height="40px" width="40px" />
            </Flex>
          </Flex>
          <Flex overflowX="auto">
            {[...Array(5)].map((_, index) => (
              <Skeleton
                key={index}
                height="200px"
                width="300px"
                mr={4}
                borderRadius="lg"
              />
            ))}
          </Flex>
        </Box>

        <Box mb={4}>
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <Skeleton height="32px" width="100px" />
            <Flex>
              <Skeleton height="40px" width="40px" mr={2} />
              <Skeleton height="40px" width="40px" />
            </Flex>
          </Flex>
          <Flex overflowX="auto">
            {[...Array(5)].map((_, index) => (
              <Skeleton
                key={index}
                height="200px"
                width="300px"
                mr={4}
                borderRadius="lg"
              />
            ))}
          </Flex>
        </Box>
      </Content>
      {/* </PageLayoutSuspense> */}
    </>
  );
}
