import React from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useInView } from "react-intersection-observer";

import type { UserMetrics } from "@charry/models";
import { time } from "@charry/utils";

import UserProfile from "~/ui/components/dashboard/UserProfile";
import useSuper from "~/ui/hooks/useSuper.hook";

const Column = ({ children }: { children: React.ReactNode }) => {
  return (
    <Td
      fontSize="14px"
      lineHeight="20px"
      fontWeight="400"
      height="60px"
      padding="0 24px"
      maxWidth="300px"
      whiteSpace="nowrap"
      borderColor="gray.800"
      color="white"
    >
      {children}
    </Td>
  );
};

const SuperUsersTable: React.FC = () => {
  const { superListUsers, superImpersonateUser } = useSuper();
  const { ref, inView } = useInView();

  const { data, fetchNextPage, isFetching } = superListUsers;

  const total = data?.pages[data.pages.length - 1]?.total ?? 0;

  //we must flatten the array of arrays from the useInfiniteQuery hook
  const filtered = React.useMemo(
    () =>
      data?.pages
        .flatMap((page) => page.users)
        .filter((g): g is UserMetrics => Boolean(g)) ?? [],
    [data],
  );
  const totalDBRowCount = total;
  const totalFetched = filtered.length;

  React.useEffect(() => {
    if (inView && !isFetching && totalFetched < totalDBRowCount) {
      fetchNextPage().catch((error) => {
        console.error(error);
      });
    }
  }, [inView, fetchNextPage, totalFetched, totalDBRowCount, isFetching]);

  return (
    <TableContainer
      boxShadow="0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
      overflow="scroll"
      borderColor="gray.800"
    >
      <Table variant="simple">
        <Thead
          borderTop="1px solid"
          borderBottom="1px solid"
          borderColor="gray.800"
        >
          <Tr backgroundColor="gray.1000" borderColor="gray.800">
            <Th borderColor="gray.800" color="white">
              User
            </Th>
            <Th borderColor="gray.800" color="white">
              Characters
            </Th>
            <Th borderColor="gray.800" color="white">
              Created
            </Th>
          </Tr>
        </Thead>
        <Tbody overflow="hidden">
          {filtered.map((user, index: number) => {
            const fullName = `${user.firstName} ${user.lastName} (${user._id})`;

            return (
              <Tr
                key={index}
                borderColor="gray.800"
                border="0px"
                w="100%"
                ref={index === filtered.length - 10 ? ref : null}
              >
                <Column>
                  <Flex flex={1} flexDir={"column"} gridGap={"8px"} py={4}>
                    <Box>
                      <UserProfile name={fullName} email={user.email} />
                    </Box>
                    <HStack>
                      <Box>
                        <Link
                          href={`https://app.posthog.com/person/${user._id}`}
                          target="_blank"
                        >
                          <Button
                            size="xs"
                            variant="secondary"
                            marginRight="8px"
                          >
                            View PostHog
                          </Button>
                        </Link>
                      </Box>
                      <Box>
                        <Button
                          size="xs"
                          variant="secondary"
                          onClick={() =>
                            superImpersonateUser.mutate({ userId: user._id })
                          }
                        >
                          Impersonate User
                        </Button>
                      </Box>
                    </HStack>
                  </Flex>
                </Column>

                <Column>
                  {/* <Text color={user.characters.length ? "green.500" : "white"}>
                    {user}
                  </Text> */}
                </Column>
                <Column>
                  {time.format(
                    user.firstSeenAt ?? 0,
                    "ff",
                    time.currentBrowserTimezone(),
                  )}
                </Column>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {filtered.length > 0 && isFetching ? (
        <Box
          flex="1"
          height="64px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </Box>
      ) : null}
    </TableContainer>
  );
};

export default SuperUsersTable;
