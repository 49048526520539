import type { Accept } from "react-dropzone";
import React from "react";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";

import { api } from "~/lib/trpc";
import Label from "../elements/Label";

type UploadToGCSParams = {
  url: string;
  file: File;
  onProgress: (event: ProgressEvent<EventTarget>) => void;
};

function uploadToGCS({
  url,
  file,
  onProgress,
}: UploadToGCSParams): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", url, true);
    xhr.onload = () => {
      const status = xhr.status;
      if (status === 200) {
        resolve(true);
      } else {
        reject(false);
      }
    };

    xhr.onerror = (error) => {
      reject(error);
    };
    xhr.setRequestHeader("Content-Type", file.type);
    xhr.upload.onprogress = onProgress;
    xhr.send(file);
  });
}

const INITIAL_PROGRESS_STATE = "0";

type FileUploadProps = {
  label?: string;
  accept?: Accept;
  onStartUpload?: (urls: string[]) => void;
  onCompleted?: (urls: string[]) => void;
};

const FileUpload: React.FC<FileUploadProps> = ({
  label,
  accept,
  onStartUpload,
  onCompleted,
}) => {
  /** Actions */
  const signedUrl = api.system.signedUrl.useMutation();

  const [progress, setProgress] = React.useState<string>(
    INITIAL_PROGRESS_STATE,
  );
  const isLoading = progress !== INITIAL_PROGRESS_STATE;

  const uploadFile = async (browserFile?: File) => {
    if (!browserFile) return;
    const { uploadUrl, imageUrl } = await signedUrl.mutateAsync({
      filename: browserFile.name,
    });

    if (uploadUrl) {
      onStartUpload?.([imageUrl]);
      const success = await uploadToGCS({
        url: uploadUrl,
        file: browserFile,
        onProgress: (event) => {
          setProgress(
            Number.parseFloat(
              String((event.loaded / event.total) * 100),
            ).toFixed(2),
          );
        },
      });

      if (success) {
        onCompleted?.([imageUrl]);
        setProgress(INITIAL_PROGRESS_STATE);
      } else {
        console.error("There was an error uploading the file.");
      }
    } else {
      console.error("There was an error creating the file.");
    }
  };

  /* Data */
  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    onDrop: ([file]: File[]) => {
      void uploadFile(file);
    },
  });

  /* Render */
  return (
    <Flex direction="column">
      {label && <Label>{label}</Label>}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100px"
        width="100px"
        boxSizing="border-box"
        position="relative"
        cursor="pointer"
        borderRadius="4px"
        border="1px solid"
        borderColor="gray.700"
        backgroundColor="gray.1100"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isLoading ? (
          <Flex direction="column" align="center" justify="center">
            <Spinner color="gray.600" />
            <Box h={2} />
            <Text color="gray.600" variant="12-reg">
              {progress}% complete.
            </Text>
          </Flex>
        ) : (
          <>
            <FiUploadCloud size="32px" color="#26272B" />
          </>
        )}
      </Box>
    </Flex>
  );
};

export default FileUpload;
