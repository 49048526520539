import React, { useState } from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import type {
  CreateCharacterParams,
  UpdateCharacterParams,
} from "@charry/models";

import type useCharacter from "~/ui/hooks/useCharacter.hook";
import type useCharacterList from "~/ui/hooks/useCharacterList.hook";

type Params = CreateCharacterParams | UpdateCharacterParams;

type CharacterTagsProps = {
  params: Params;
  setParams:
    | ReturnType<typeof useCharacterList>["setCreateCharacterParams"]
    | ReturnType<typeof useCharacter>["setUpdateCharacterParams"];
  errors: { tags?: string };
};

const CharacterTags: React.FC<CharacterTagsProps> = ({
  params,
  setParams,
  errors,
}) => {
  const [inputValue, setInputValue] = useState("");

  const addTag = (tag: string) => {
    if (tag && !params.tags?.includes(tag)) {
      setParams((prev) => ({
        ...prev,
        tags: prev.tags ? [...prev.tags, tag] : [tag],
      }));
    }
  };

  const removeTag = (tagToRemove: string) => {
    setParams((prev) => ({
      ...prev,
      tags: prev.tags?.filter((tag) => tag !== tagToRemove),
    }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const tag = inputValue.trim();
      if (tag) {
        addTag(tag);
        setInputValue("");
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleBlur = () => {
    const tag = inputValue.trim();
    if (tag) {
      addTag(tag);
      setInputValue("");
    }
  };

  return (
    <Flex direction="column">
      <FormControl isInvalid={!!errors.tags}>
        <FormLabel>Tags</FormLabel>
        <Input
          type="text"
          placeholder="Add tags and press Enter or comma"
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
        <Wrap mt={2}>
          {params.tags?.map((tag, index) => (
            <WrapItem key={index}>
              <Tag
                size="md"
                borderRadius="full"
                variant="solid"
                colorScheme="blue"
              >
                <TagLabel>{tag}</TagLabel>
                <TagCloseButton onClick={() => removeTag(tag)} />
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
        {errors.tags && <FormErrorMessage>{errors.tags}</FormErrorMessage>}
      </FormControl>
    </Flex>
  );
};

export default CharacterTags;
